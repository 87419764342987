import React from "react";

const SpamGuidelines = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Spam Guidelines</h2>
        <p className="text-[#7D8592] mb-4">
          At Trackmydoc, we are committed to maintaining a safe, respectful, and
          professional environment for all users of our services. As part of
          this commitment, we have established these Spam Guidelines to define
          what constitutes spam and to ensure that our platform is used
          responsibly and ethically.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Definition of Spam</h2>
          <div>
            <p>
              For the purposes of these guidelines, spam refers to any
              unsolicited or unwanted communication that is sent in bulk,
              usually for commercial purposes. Spam can include, but is not
              limited to, the following:
            </p>
            <ul>
              <li>
                <strong>Unsolicited emails:</strong> Sending emails to users who
                have not opted-in or consented to receiving such communications.
              </li>
              <li>
                <strong>Unsolicited messages:</strong> Sending unsolicited
                direct messages via our platform or other communication
                channels.
              </li>
              <li>
                <strong>Bulk or automated messaging:</strong> Using automated
                systems or scripts to send bulk messages or perform actions that
                are intended to overwhelm or disrupt the service.
              </li>
              <li>
                <strong>Fake or misleading content:</strong> Sending content
                that misrepresents the sender’s identity, promotes fraudulent
                services, or misleads users.
              </li>
              <li>
                <strong>Malicious content:</strong> Sending messages that
                contain viruses, malware, or other harmful software intended to
                damage or disrupt users' devices or the service.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Prohibited Actions</h2>
          <div>
            <p>
              The following actions are strictly prohibited on our platform and
              may result in the suspension or termination of your account:
            </p>
            <ul>
              <li>
                <strong>Sending unsolicited commercial messages:</strong>{" "}
                Sending marketing or promotional emails to users who have not
                consented to receive such communications.
              </li>
              <li>
                <strong>Using misleading or deceptive subject lines:</strong>{" "}
                Creating email subject lines or messages that deceive recipients
                about the content or sender.
              </li>
              <li>
                <strong>Harvesting or scraping contact information:</strong>{" "}
                Collecting email addresses, phone numbers, or other personal
                information from our users without their consent.
              </li>
              <li>
                <strong>Engaging in email bombing:</strong> Sending excessive or
                repeated messages to a user in a short period of time to
                overwhelm or annoy them.
              </li>
              <li>
                <strong>Using automated tools:</strong> Employing bots, scripts,
                or other automated tools to send bulk messages or conduct any
                form of spam-related activity.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Reporting Spam</h2>
          <div>
            <p>
              If you encounter spam or suspect that spam-related activities are
              taking place on our platform, we encourage you to report it to us
              immediately. We take all reports seriously and will investigate
              the issue promptly.
            </p>
            <p>You can report spam by:</p>
            <ul>
              <li>
                <strong>Contacting Support:</strong> Email us at [support email]
                with details of the spam activity.
              </li>
              <li>
                <strong>Using the Reporting Feature:</strong> Use the “Report”
                button available in the platform to flag any suspicious or
                spam-related content.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">
            4. Consequences of Violating Spam Guidelines
          </h2>
          <div>
            <p>
              Any user found to be in violation of these Spam Guidelines will
              face the following consequences:
            </p>
            <ul>
              <li>
                <strong>Account Suspension:</strong> Your account may be
                temporarily suspended while we investigate the violation.
              </li>
              <li>
                <strong>Permanent Ban:</strong> If the violation is severe or
                repeated, your account may be permanently banned from using our
                services.
              </li>
              <li>
                <strong>Legal Action:</strong> In cases of fraudulent or illegal
                activity, we may pursue legal action against the responsible
                party.
              </li>
            </ul>
            <p>
              We reserve the right to take any actions necessary to protect our
              platform and users from spam-related activities.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Best Practices for Preventing Spam</h2>
          <div>
            <p>
              To ensure that you are using our platform responsibly and not
              engaging in spam-related activities, we recommend following these
              best practices:
            </p>
            <ul>
              <li>
                <strong>Get consent:</strong> Always obtain explicit consent
                from users before sending marketing or promotional messages.
              </li>
              <li>
                <strong>Provide an opt-out option:</strong> Ensure that all
                communications include a clear and easy way for recipients to
                unsubscribe or opt-out from future messages.
              </li>
              <li>
                <strong>Be transparent:</strong> Make sure your communications
                clearly identify you as the sender and accurately represent the
                content of the message.
              </li>
              <li>
                <strong>Monitor your messaging:</strong> Regularly review the
                content you send to ensure that it adheres to our guidelines and
                is not considered spam.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Changes to the Spam Guidelines</h2>
          <div>
            <p>
              We reserve the right to update or modify these Spam Guidelines at
              any time. Any changes will be posted on this page, and the updated
              guidelines will take effect immediately upon posting. Please
              review this page regularly to stay informed of any updates.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. Contact Information</h2>
          <div>
            <p>
              If you have any questions or concerns regarding these Spam
              Guidelines or need assistance with reporting spam, please contact
              us:
            </p>
            <p>
              <strong>Trackmydoc, LLC</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpamGuidelines;
