import React from "react";

const TrackmydocAcademy = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Trackmydoc Academy Terms of Use</h2>
        <p className="text-[#7D8592] mb-4">
          Welcome to Trackmydoc Academy! These Terms of Use ("Terms") govern
          your access to and use of the educational resources, courses, and
          services provided by Trackmydoc Academy. By accessing or using
          Trackmydoc Academy, you agree to comply with and be bound by these
          Terms. If you do not agree with these Terms, you may not access or use
          Trackmydoc Academy.
        </p>
        <p className="text-[#7D8592]">
          Please read these Terms carefully before using Trackmydoc Academy.
          These Terms apply to all users, including students, instructors, and
          any other individuals who access or use the Academy’s services.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Access to Trackmydoc Academy</h2>
          <div>
            <p>
              To access Trackmydoc Academy, you must create an account by
              providing accurate and up-to-date information. You are responsible
              for maintaining the confidentiality of your account credentials
              and for all activities that occur under your account.
            </p>
            <p>
              By using Trackmydoc Academy, you agree to comply with all
              applicable laws and regulations. You may only use the Academy for
              lawful purposes and in accordance with these Terms.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Use of Educational Content</h2>
          <div>
            <p>
              The content provided on Trackmydoc Academy, including courses,
              videos, articles, and other materials , is protected by
              intellectual property laws and is the property of Trackmydoc or
              its licensors. You are granted a limited, non-exclusive,
              non-transferable license to access and use the Content for
              personal, non-commercial purposes only.
            </p>
            <p>
              You may not reproduce, distribute, modify, or create derivative
              works of the Content without prior written permission from
              Trackmydoc.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. User Conduct</h2>
          <div>
            <p>As a user of Trackmydoc Academy, you agree to:</p>
            <ul>
              <li>
                Use the Academy in a manner that does not infringe the rights of
                others or violate any applicable laws or regulations.
              </li>
              <li>
                Not engage in any conduct that disrupts or interferes with the
                functioning of the Academy or the experience of other users.
              </li>
              <li>
                Not upload, post, or share any content that is offensive,
                harmful, or violates the rights of others.
              </li>
              <li>
                Comply with all applicable terms and conditions when interacting
                with other users or instructors on the platform.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Account Termination</h2>
          <div>
            <p>
              Trackmydoc reserves the right to suspend or terminate your account
              if you violate these Terms or engage in any conduct that is
              harmful to the Academy or other users. Upon termination, you will
              lose access to all Content and services associated with your
              account.
            </p>
            <p>
              You may also terminate your account at any time by contacting
              Trackmydoc support or through your account settings.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Fees and Payments</h2>
          <div>
            <p>
              Trackmydoc Academy may charge fees for certain courses or
              services. The fees and payment terms will be clearly outlined at
              the time of purchase. By purchasing a course or service, you agree
              to pay the applicable fees in full.
            </p>
            <p>
              All payments must be made through the payment methods provided on
              the Academy platform. If payment is not received or is declined,
              access to the courses or services may be suspended or terminated.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Refund Policy</h2>
          <div>
            <p>
              Refunds for courses or services provided by Trackmydoc Academy are
              subject to the refund policy outlined on the Academy platform.
              Please review the refund policy before making any purchase.
              Refunds may be granted under certain conditions, as specified in
              the policy.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. Privacy and Data Protection</h2>
          <div>
            <p>
              Your privacy is important to us. Please review our Privacy Policy
              to understand how we collect, use, and protect your personal
              information when you use Trackmydoc Academy.
            </p>
            <p>
              By using the Academy, you consent to the collection and use of
              your personal information in accordance with our Privacy Policy.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">
            8. Disclaimers and Limitation of Liability
          </h2>
          <div>
            <p>
              Trackmydoc Academy provides its services and Content "as is" and
              makes no representations or warranties of any kind, either express
              or implied, regarding the accuracy, completeness, or usefulness of
              the Content. We do not guarantee that the Academy will be free
              from errors or interruptions.
            </p>
            <p>
              Trackmydoc is not liable for any damages, losses, or costs
              incurred as a result of using the Academy, including but not
              limited to indirect, incidental, or consequential damages.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">9. Changes to the Terms of Use</h2>
          <div>
            <p>
              Trackmydoc reserves the right to modify or update these Terms at
              any time. Any changes will be posted on the Academy website, and
              you will be notified of any material changes. By continuing to use
              the Academy after changes are made, you agree to be bound by the
              updated Terms.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">10. Governing Law</h2>
          <div>
            <p>
              These Terms are governed by the laws of the jurisdiction in which
              Trackmydoc is located, without regard to its conflict of law
              principles. Any disputes arising from these Terms shall be
              resolved in the appropriate courts located
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">11. Contact Information</h2>
          <div>
            <p>
              If you have any questions or concerns regarding these Terms of Use
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackmydocAcademy;
