import React from "react";

const ProgramAgreement = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Partner Program Agreement</h2>

        <p className="text-[#7D8592]">
          This Agreement governs the terms and conditions under which the
          Partner will participate in the Company’s Partner Program.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Program Participation</h2>
          <div>
            <p>
              The Partner agrees to participate in the Program in accordance
              with the terms and conditions set forth in this Agreement. The
              Program allows the Partner to promote and/or resell the Company's
              products and services in exchange for compensation as described
              herein.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Responsibilities of the Partner</h2>
          <div>
            <p>The Partner agrees to the following responsibilities:</p>
            <ul>
              <li>
                <strong>Promotion:</strong> The Partner will actively promote
                the Company's products and services through various channels,
                including but not limited to online marketing, email campaigns,
                and direct sales.
              </li>
              <li>
                <strong>Compliance:</strong> The Partner will comply with all
                applicable laws, regulations, and industry standards in the
                promotion of the Company’s products and services.
              </li>
              <li>
                <strong>Customer Support:</strong> The Partner will provide
                first-line customer support to customers acquired through their
                efforts, escalating issues to the Company as necessary.
              </li>
              <li>
                <strong>Brand Guidelines:</strong> The Partner will adhere to
                the Company’s brand guidelines when using the Company’s
                trademarks, logos, and other intellectual property in marketing
                materials.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Responsibilities of the Company</h2>
          <div>
            <p>The Company agrees to the following responsibilities:</p>
            <ul>
              <li>
                <strong>Product Information:</strong> The Company will provide
                the Partner with up-to-date information about its products and
                services to facilitate accurate promotion and sales.
              </li>
              <li>
                <strong>Training and Support:</strong> The Company will provide
                necessary training and ongoing support to the Partner to ensure
                the success of the Program.
              </li>
              <li>
                <strong>Compensation:</strong> The Company will compensate the
                Partner in accordance with the compensation structure outlined
                in this Agreement.
              </li>
              <li>
                <strong>Marketing Materials:</strong> The Company will provide
                marketing materials and resources to assist the Partner in
                promoting its products and services.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Compensation</h2>
          <div>
            <p>
              The Partner will be compensated based on the following structure:
            </p>
            <ul>
              <li>
                <strong>Commission:</strong> The Partner will earn a commission
                on each sale made through their efforts. The commission will be
                paid on a basis.
              </li>
              <li>
                <strong>Bonus Structure:</strong> The Partner may be eligible
                for additional bonuses based on performance metrics such as
                sales volume, customer acquisition, or other agreed-upon
                targets.
              </li>
              <li>
                <strong>Payment Terms:</strong> Payments will be made within
                days of the end of the payment period, subject to any necessary
                deductions for refunds or chargebacks.
              </li>
            </ul>
            <p>
              All compensation amounts are subject to applicable taxes, and the
              Partner is responsible for any taxes associated with their
              earnings.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Term and Termination</h2>
          <div>
            <p>
              This Agreement will begin on the Effective Date and will remain in
              effect until terminated by either Party. Either Party may
              terminate this Agreement at any time by providing days' written
              notice to the other Party.
            </p>
            <p>
              Upon termination, the Partner will cease all promotional
              activities related to the Company’s products and services and will
              return any materials or intellectual property provided by the
              Company.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Confidentiality</h2>
          <div>
            <p>
              Both Parties agree to keep confidential all information disclosed
              during the term of this Agreement that is designated as
              confidential or that a reasonable person would consider
              confidential. This includes, but is not limited to, business
              plans, marketing strategies, customer data, and financial
              information.
            </p>
            <p>
              Confidential information may not be disclosed to any third party
              without the prior written consent of the disclosing Party, except
              as required by law.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. Intellectual Property</h2>
          <div>
            <p>
              Each Party retains ownership of its own intellectual property,
              including trademarks, copyrights, and patents. The Company grants
              the Partner a limited, non-exclusive license to use its trademarks
              and branding materials solely for the purpose of promoting its
              products and services under the terms of this Agreement.
            </p>
            <p>
              The Partner agrees not to use the Company’s intellectual property
              in any manner that could harm the Company’s reputation or brand.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">8. Indemnification</h2>
          <div>
            <p>
              Each Party agrees to indemnify and hold harmless the other Party
              from any claims, damages, or liabilities arising out of the
              indemnifying Party’s breach of this Agreement, negligence, or
              misconduct. This includes, but is not limited to, any claims
              arising from the Partner’s promotional activities or the use of
              the Company’s products and services.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">
            9. Governing Law and Dispute Resolution
          </h2>
          <div>
            <p>
              This Agreement will be governed by and construed in accordance
              with the laws of the State of without regard to its conflict of
              law principles. Any disputes arising under or in connection with
              this Agreement will be resolved through and the decision will be
              binding on both Parties.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">10. Miscellaneous</h2>
          <div>
            <p>
              This Agreement constitutes the entire understanding between the
              Parties and supersedes all prior agreements or understandings,
              whether written or oral, related to the subject matter of this
              Agreement.
            </p>
            <p>
              Any amendments to this Agreement must be in writing and signed by
              both Parties.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">11. Execution</h2>
          <div>
            <p>
              By signing below, the Parties acknowledge that they have read,
              understood, and agreed to the terms and conditions of this
              Agreement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramAgreement;
