import React from "react";

const TermsOfService = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Terms of Service</h2>
        <p className="text-[#7D8592] mb-4">
          Trackmydoc provides its services ("Services") to you subject to these
          Terms of Service ("Terms"). By accessing or using the Services, which
          include all software, mobile applications, websites, and related
          services provided by Trackmydoc, you agree to be bound by these Terms,
          the Privacy Policy, and any other applicable policies or agreements
          collectively referred to as the "Agreement."
        </p>
        <p className="text-[#7D8592]">
          If you do not agree to the Agreement in its entirety, you may not
          access or use the Services. Please read these Terms carefully before
          creating an account or using the Services, as they outline your
          rights, responsibilities, and limitations in connection with your use
          of the Services.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Definitions</h2>
          <div>
            <p>The accompanying definitions apply to these terms:</p>
            <p>
              «Agreement» signifies these Terms, notwithstanding our Privacy
              Policy, Data Processing Amendment («DPA»), Business Associate
              Agreement («BAA»), Refund Policy, Spam Policy, Partner Program
              Terms and Referral Program Terms, and any other electronic or
              composed agreement essentially between or pertinent to Trackmydoc
              and Customer administering the agreement of the Service to you, as
              corrected every now and then.
            </p>
            {/* Add other definitions here as needed */}
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">
            2. License for Use of the Service; Identity Authentication
          </h2>
          <div>
            <p>
              The Service is licensed for use only under these Terms. Trackmydoc
              reserves all rights not expressly granted to you, including title
              and exclusive ownership of the Service, any and all software or
              updates thereto, and source code for the Service.
            </p>
            <p>
              Upon registering for the Service, Trackmydoc gives you the right
              to install the Service for use by the total number of Users you
              identify and authorize. The Service may not be used or accessed by
              unauthorized individuals or devices.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Payment Processing</h2>
          <div>
            <p>
              In order to pay your Subscription Fees through the Services, you
              will be required to enter your billing information, including
              credit card details. Payments are processed through third-party
              payment processors such as Stripe or CPAcharge, subject to their
              terms and conditions.
            </p>
            <p>
              Trackmydoc is not responsible for any errors or breaches occurring
              on the payment processor's servers. All non-financial billing
              information will be available to Trackmydoc for invoicing and
              record-keeping purposes.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Term and Fees</h2>
          <div>
            <p>
              Provided prior notice of pricing is given to you, Trackmydoc
              reserves the right to charge for the use of the Service on a
              periodic basis. These Terms shall begin upon your registration and
              continue until terminated by you or Trackmydoc.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Other Terms</h2>
          <div>
            <p>
              Upon the termination of these Terms, the Service and all updates
              may cease to function, and warranties regarding the Service shall
              terminate. Trackmydoc reserves the right to terminate access for
              any actions deemed inappropriate or unlawful.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
