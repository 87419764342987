import React from "react";
import laptop1 from "../assets/img/laptop-1.svg";
import device1 from "../assets/img/device-1.svg";
import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import templates from "../assets/img/templates.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import filtericon from "../assets/img/filter-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";
import TeamMenagment from "../assets/img/TeamMenagment.svg";
import secureunlimiteddocument from "../assets/img/secure-unlimited-document.svg";
import documentmanagment from "../assets/img/document-managment.svg";
import getfilesfromclients from "../assets/img/getfilesfromclients.svg";
import messagingcapabilities from "../assets/img/messaging-capabilities.svg";
import SecureMessagesicon from "../assets/img/SecureMessages.svg";
import Request from "./button/Request";
import FaqSection from "./Accordion/Accordion";

const Product = () => {
  const features = [
    { icon: solepractitionerlogo, title: "Secure access", description: "Ensure the protection of client data with secure organizer access" },
    { icon: templates, title: "Templates", description: "Save time by creating organizer templates" },
    { icon: printericon, title: "Conditions", description: "Set conditions to skip questions or show previous answers" },
    { icon: folderopenicon, title: "Custom fields", description: "Choose the optimal answer format for your clients: radio buttons, checkboxes, etc." },
    { icon: filtericon, title: "Tags", description: "Automatically integrate answers from organizers into your CRM using tags" },
    { icon: clipboardtexticon, title: "Audit trail", description: "A detailed record of the organizer questions and answers is saved in the audit trail" },
    { icon: solepractitionerlogo, title: "Automations", description: "Automate when your organizer is sent to clients and set reminders to nudge them" },
    { icon: cardediticon, title: "Linking to jobs", description: "Run your practice smoothly by linking organizers to jobs to incorporate them into your workflow" },
  ];
  const FAQData = [
    {
      question: "Store client and prospect information",
      answer: "Gather the best-known insights about your clients and leverage that data to serve them better. Empower yourself with the best CRM for small businesses and world-class CRM solutions and get all client information in one central hub,enhancing client servicing."
    },
    {
      question: "Simplified account management",
      answer: "Bring your company’s leads and clients on a single dashboard and configure your CRM hub with client-centric workflows, unlimited contacts, custom fields, tags, filters, bulk actions, and more. With an AI-powered CRM platform, establish a common link between your contacts and accounts to build and track relationships."
    },
    {
      question: "End-to-end management control",
      answer: "TrackmyDoc is your single point of organization and automation for accounting, payroll, and bookkeeping processes.Get client communication, client intake process, creation of tasks and jobs on a preset schedule, billing, engagement letters, custom CRM templates, and CRM input, all done swiftly and flawlessly with little intervention needed."
    },
    {
      question: "Onboard clients in a snap",
      answer: "Use the best CRM software to save time for your team and efficiently serve more clients. With the help of AI-powered and automated CRM platforms, facilitate easy and new client onboarding. From capturing a lead through your personalized login page to e-signing ready files, make it all simple and quick."
    },
    {
      question: "Automated reminders",
      answer: " Automate reminders to keep your clients on track without any manual intervention with the help of TrackmyDoc. Forget manual follow-ups, and with automation, quickly remind clients about pending organizers, invoices, contracts, chat messages, approvals, signatures, and file requests. With efficient e-signature tax software, billing and invoicing software, recurring invoice solutions, and more, save hours of manual work while nurturing client engagement."
    },
  ]
  const StoreClientData = [
    {
      question: "Store client and prospect information",
      answer:
        "Gather the best-known insights about your clients and leverage that data to serve them better. Empower yourself with the best CRM for small businesses and world-class CRM solutions and get all client information in one central hub, enhancing client servicing.",
    },
    {
      question: "Simplified account management",
      answer:
        "Bring your company’s leads and clients on a single dashboard and configure your CRM hub with client-centric workflows, unlimited contacts, custom fields, tags, filters, bulk actions, and more. With an AI-powered CRM platform, establish a common link between your contacts and accounts to build and track relationships.",
    },
    {
      question: "End-to-end management control",
      answer:
        "TrackmyDoc is your single point of organization and automation for accounting, payroll, and bookkeeping processes. Get client communication, client intake process, creation of tasks and jobs on a preset schedule, billing, engagement letters, custom CRM templates, and CRM input, all done swiftly and flawlessly with little intervention needed.",
    },
    {
      question: "Onboard clients in a snap",
      answer:
        "Use the best CRM software to save time for your team and efficiently serve more clients. With the help of AI-powered and automated CRM platforms, facilitate easy and new client onboarding. From capturing a lead through your personalized login page to e-signing ready files, make it all simple and quick.",
    },
    {
      question: "Automated reminders",
      answer:
        "Automate reminders to keep your clients on track without any manual intervention with the help of TrackmyDoc. Forget manual follow-ups, and with automation, quickly remind clients about pending organizers, invoices, contracts, chat messages, approvals, signatures, and file requests. With efficient e-signature tax software, billing and invoicing software, recurring invoice solutions, and more, save hours of manual work while nurturing client engagement.",
    },
  ];

  const SecurePlace = [
    {
      question: "Secure unlimited document storage",
      answer:
        "With the best CRM for small businesses, up your game like never before! With AI-powered CRM and Cloud CRM solutions, upload files directly from your computer or tax program to your cloud-based portal without any risks. Securely store client documents without any limitations or hesitation.",
    },
    {
      question: "Centralized file management",
      answer:
        "With our versatile TrackmyDoc solution curated for CPA, Insurance Agent, Immigration Consultant, and more, get the best features with an AI-powered CRM and efficiently accommodate all business and client files in one document management system. With well-organized client information, your team can effortlessly access and collaborate on the documents whenever, wherever.",
    },
    {
      question: "Smart desktop assistant",
      answer:
        "TrackmyDoc’s Windows desktop app enhances your document flow. Leave your worries aside and effortlessly upload documents and folders from your machine directly to your client accounts, keeping track of every activity. Establish a connection between documents and specific jobs, upload folders in bulk, and connect documents to invoices with our automated CRM system.",
    },
    {
      question: "View and edit files from Windows Explorer",
      answer:
        "With TrackmyDoc’s built-in intelligence and technological innovation, manage all documents from the virtual drive that becomes a dedicated drive on your computer. This helps free up space on your machine, and you can accomplish any task quickly. With the best CRM software, your team can copy and move files and folders, upload documents manually or in bulk, edit docs locally, and auto-save changes directly to the app.",
    },
    {
      question: "Integrated with all tax software",
      answer:
        "TrackmyDoc helps print documents from any tax program eliminating redundant data entry. Leverage the best e-signature tax software, client management tools, and tax preparation software for the best business outcomes.",
    },
    {
      question: "Unlimited cloud-based storage",
      answer:
        "TrackmyDoc facilitates unlimited, safe, secure cloud storage at no extra cost. Get the best out of TrackmyDoc and securely store documents.",
    },
  ]

  const ClientPortal = [
    {
      question: "Clients upload docs without login",
      answer:
        "Need to forward an important document to a lawyer or a financial advisor? Securely share documents with third parties and provide expiring links for added security measures.",
    },
    {
      question: "Third-party sharing",
      answer:
        "Need to forward an important document to a lawyer or a financial advisor? Securely share documents with third parties and provide expiring links for added security measures.",
    },
    {
      question: "Streamline client feedback",
      answer:
        "Need to forward an important document to a lawyer or a financial advisor? Securely share documents with third parties and provide expiring links for added security measures.",
    },
  ];

  const MessagingCapabilities = [
    {
      question: "A client portal with built-in chats",
      answer:
        "With its AI-powered CRM platform, TrackmyDoc offers new-age features that enhance client relationships. With the best CRM software and client management tools, clients can view your messages immediately in their client portal on desktop or native iOS and Android apps. Show message content in email notifications or only in the portal, based on your preferences.",
    },
    {
      question: "Send instant messages from mobile devices",
      answer:
        "Here’s a modern way for your clients to communicate with your team while they’re out and about with our native iOS and Android client mobile apps. In addition, the web-based client portal offers seamless communication and enables instant messaging from mobile devices. TrackmyDoc’s Cloud CRM solutions offer companies and clients native apps to communicate on the go.",
    },
    {
      question: "Attach links, images, and videos",
      answer:
        "If you want to add visuals to your messages for clients, do it innovatively and seamlessly with our native iOS and Android client mobile apps. With the power of TrackmyDoc, include files and videos in the chat when it’s easier to show than tell and communicate more effectively and quickly.",
    },
  ]
  return (
    <>
      <div className="common-margin all-in-one-solution">
        <div className="website-container">
          <div className="block lg:flex gap-5 align-items-center">
            <div className="me-xl-5 w-full lg:w-1/2">
              <div className="_text mb-60">
                <span className="mb-12 fw-500 d-block primary-color">Grow with Trackmydoc</span>
                <h2 className="mb-20">The ideal CRM software for client-facing teams</h2>
                <p className="mb-0">
                  Manage your clients seamlessly, seize the right leads, and keep all records of your contacts and account information
                  well-organized. In addition, CRM software enables you to collect payments, interact with clients, and gain insights into
                  your team's performance, helping you grow revenue.
                </p>
              </div>
              <div className="accordion mb-01 me-3 res-mb-40">
                <FaqSection data={StoreClientData} subTitle='' Title='' isFaq="false" />
              </div>
            </div>
            <div className="min-height-850 bg-color radius-24 w-full lg:w-1/2">
              <div className="combine-picture text-center p-5">
                <img src={laptop1} />
                <img className="second-img" src={device1} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="customization common-padding">
        <div className="website-container">
          <div className="text-center mb-20">
            <h2 className="mb-5 text-3xl font-bold">Create tax organizers customized to your clients</h2>
            <p className="max-w-3xl mx-auto text-gray-700">
              Make use of various organizer features to gather and sort client data. Send 1 or 10,000 at just the right time, either
              manually or automatically, and receive timely notifications when they're completed.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {features.map((feature, index) => (
              <div key={index} className="box p-6 border border-gray-200 rounded-lg shadow-sm">
                <div className="flex items-center mb-4">
                  <div className="icon flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full mr-4">
                    <img src={feature.icon} alt={feature.title} className="w-8 h-8" />
                  </div>
                  <h5 className="text-lg font-semibold">{feature.title}</h5>
                </div>
                <p className="text-gray-600 min-72">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="hero-section common-padding">
        <div className="website-container">
          <div className="block lg:flex gap-5 items-center">
            <div className="__text res-mb-40 w-full lg:w-1/2">
              <h2 className="mb-5 text-3xl font-bold">
                Team Collaboration and Workload Visibility in One Shared Space
              </h2>
              <p className="mb-10 text-gray-700 leading-relaxed">
                With TrackmyDoc, manage teams of every size efficiently. Analyze and assign the tasks/projects per team with complete
                visibility into the workload. Align cross-functional teams and help them stay focused on the company’s present and future
                goals. Let the best CRM for small businesses help enable organizations to maintain high-quality service and delivery
                within stipulated timelines with effective team collaboration and communication. Leverage Cloud CRM solutions and
                AI-powered CRM platforms to share documents, send emails, and tag team members within a centralized, shared workspace
                designed for collaborative teamwork and high productivity.
              </p>
              <div className="flex">
                <Request />
              </div>
            </div>

            <div className="hero-picture w-full lg:w-1/2">
              <img src={TeamMenagment} alt="Team Management" className="w-full h-auto rounded-lg shadow-md" />
            </div>
          </div>
        </div>
      </div>

      <div className="hero-section common-padding">
        <div className="website-container">
          <div className="block lg:flex gap-5 items-center">
            <div className="hero-picture w-full lg:w-1/2">
              <img src={documentmanagment} alt="Document Management" className="w-full h-auto" />
            </div>

            <div className="__text res-mb-40 w-full lg:w-1/2">
              <h2 className="mb-5 text-3xl font-bold">
                Secure Document Management for Accounting Professionals
              </h2>
              <p className="mb-10 text-gray-700 leading-relaxed">
                Customized and robustly designed TrackmyDoc enables CPA, Insurance Agent, and Immigration Consultant to securely store, manage, and share
                documents with clients at a centralized hub. With the best CRM software and online tax organizer, keep all your files
                well-organized and easily accessible on one platform. Complete with e-signatures, a client portal, and an integrated
                affordable CRM for startups, TrackmyDoc simplifies client communication and boosts productivity.
              </p>
              <div className="flex">
                <Request />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin">
        <div className="website-container">
          <div className="block lg:flex gap-5 align-items-center">
            <div className="col-xl-pdright w-full lg:w-1/2">
              <div className="_text mb-60">
                <span className="mb-12 fw-500 d-block primary-color">Keep your documents in one secure place</span>
                <h2 className="mb-20">Secure, unlimited document storage</h2>
                <p className="mb-60">
                  Upload files directly from your computer or tax program to your cloud-based portal and securely store client documents
                  without any limitations.
                </p>
              </div>
              <div className="accordion mb-01 me-3 res-mb-40">
                <FaqSection data={SecurePlace} subTitle='' Title='' isFaq="false" />
              </div>
            </div>
            <div className="min-height-750 bg-color radius-24 w-full lg:w-1/2">
              <div className="combine-picture text-center py-5">
                <img src={secureunlimiteddocument} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin common-padding">
        <div className="website-container">
          <div className="block lg:flex gap-5 align-items-center">
            <div className="min-height-750 bg-color radius-24 w-full lg:1/2">
              <div className="combine-picture text-center p-5">
                <img src={getfilesfromclients} />
              </div>
            </div>
            <div className="col-xl-pdright w-full lg:1/2">
              <div className="_text mb-60">
                <span className="mb-12 fw-500 d-block primary-color">Get files from clients in a blink</span>
                <h2 className="mb-20">Integrated with the client portal</h2>
                <p className="mb-60">
                  Trackmydoc offers a document management hub that is integrated with the client portal and CRM. It provides a multitude
                  of simple and secure ways to receive documents from your clients and third parties
                </p>
              </div>
              <div className="accordion mb-01 me-3 res-mb-40">
                <FaqSection data={ClientPortal} subTitle='' Title='' isFaq="false" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hero-section common-padding">
        <div className="website-container">
          <div className="block lg:flex gap-5 align-items-center">
            <div className="__text res-mb-40 w-full lg:w-1/2">
              <h2 className="mb-20">Secure Messages & Client Tasks</h2>
              <p className="mb-60">
                Connect with your clients directly and securely within your workflow with Trackmydoc’s efficient and modernized integrated
                messaging feature. TrackmyDoc is a highly secure, efficient platform designed for CPA, Insurance Agent, and Immigration Consultant that
                enables professionals to chat in real time, upload or scan documents, request information easily, and automatically send
                reminders to clients to complete tasks. Leverage the best digital tax preparation tools, e-signature tax software, tax
                preparation software, and more—all under one umbrella of customized Cloud CRM solutions.
              </p>
              <div className="d-flex">
                <Request />
              </div>
            </div>
            <div className="hero-picture w-full lg:w-1/2">
              <img src={SecureMessagesicon} />
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin">
        <div className="website-container">
          <div className="block lg:flex gap-5 align-items-center">
            <div className="min-height-750 bg-color radius-24 w-full lg:w-1/2">
              <div className="combine-picture text-center p-5">
                <img src={messagingcapabilities} />
              </div>
            </div>
            <div className="col-xl-pdright w-full lg:w-1/2">
              <div className="_text mb-60">
                <span className="mb-12 fw-500 d-block primary-color">Let clients communicate with you on the go</span>
                <h2 className="mb-20">Messaging capabilities integrated with a client portal</h2>
                <p className="mb-60">
                  With incredibly versatile messaging capabilities integrated into the client portal, TrackmyDoc offers the easiest and
                  safest ways to communicate and share documents. Do all this and more through your client portal or mobile devices.
                </p>
              </div>
              <div className="accordion mb-01 me-3 res-mb-40">
                <FaqSection data={MessagingCapabilities} subTitle='' Title='' isFaq="false" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FaqSection data={FAQData} subTitle='Your concerns, answered' Title='Frequently asked questions' />
    </>
  );
};

export default Product;
