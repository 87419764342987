import React from "react";

const Security = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Security</h2>
        <p className="text-[#7D8592] mb-4">
          At Trackmydoc, we take the security of your personal data and
          information seriously. We have implemented a variety of security
          measures to ensure the confidentiality, integrity, and availability of
          your data. This Security Statement outlines the security practices we
          follow to protect your information while using our services.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Data Encryption</h2>
          <div>
            <p>
              All sensitive data transmitted between your device and our servers
              is encrypted using Secure Socket Layer (SSL) technology. This
              ensures that any data exchanged is protected from unauthorized
              access during transmission.
            </p>
            <p>
              We also use encryption to store sensitive data at rest, ensuring
              that your personal information is protected even in the event of a
              breach.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Authentication</h2>
          <div>
            <p>
              To ensure that only authorized users can access your account, we
              use secure authentication methods, including:
            </p>
            <ul>
              <li>
                <strong>Two-Factor Authentication (2FA):</strong> We offer an
                additional layer of security by requiring a second form of
                verification, such as a code sent to your mobile device.
              </li>
              <li>
                <strong>Strong Password Policies:</strong> We enforce the use of
                strong, unique passwords to reduce the risk of unauthorized
                access to your account.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Secure Payment Processing</h2>
          <div>
            <p>
              When you make payments through our platform, your payment
              information is processed by a third-party payment processor (e.g.,
              Stripe, PayPal). These processors use industry-standard security
              protocols, including encryption, to protect your financial
              information.
            </p>
            <p>
              We do not store any sensitive payment information, such as credit
              card numbers, on our servers.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Data Access Control</h2>
          <div>
            <p>
              Access to your personal data is restricted to authorized personnel
              only. We implement role-based access control to ensure that
              employees or contractors who require access to data for their work
              can do so without compromising your privacy.
            </p>
            <p>
              Additionally, we regularly audit access logs to ensure that only
              authorized users have access to sensitive information.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Regular Security Audits</h2>
          <div>
            <p>
              We perform regular security audits and vulnerability assessments
              to identify potential security risks. These assessments help us
              ensure that our systems remain secure and that any vulnerabilities
              are addressed promptly.
            </p>
            <p>
              We also work with third-party security experts to conduct
              penetration testing and other security assessments to ensure that
              our platform remains secure against evolving threats.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Incident Response</h2>
          <div>
            <p>
              In the unlikely event of a data breach or security incident, we
              have an incident response plan in place to quickly address the
              situation. This includes:
            </p>
            <ul>
              <li>Notifying affected users as soon as possible.</li>
              <li>
                Investigating the cause of the breach and taking steps to
                mitigate further risks.
              </li>
              <li>
                Working with law enforcement and regulatory authorities as
                needed.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. User Responsibilities</h2>
          <div>
            <p>
              While we take extensive measures to protect your data, it is also
              important for you to play an active role in securing your account.
              We recommend the following:
            </p>
            <ul>
              <li>
                Use strong, unique passwords for your account and avoid reusing
                passwords across different platforms.
              </li>
              <li>
                Enable two-factor authentication (2FA) for an added layer of
                security.
              </li>
              <li>
                Notify us immediately if you suspect any unauthorized access to
                your account.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">8. Changes to This Security Statement</h2>
          <div>
            <p>
              We may update this Security Statement from time to time to reflect
              changes in our security practices or legal requirements. Any
              updates will be posted on our website, and the revised statement
              will be effective as of the date of posting.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">9. Contact Information</h2>
          <div>
            <p>
              If you have any questions or concerns regarding our security
              practices or this Security Statement, please contact us:
            </p>
            <p>
              <strong>Trackmydoc, LLC</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
