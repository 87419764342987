import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/img/logo.svg";
import { Link, NavLink } from "react-router-dom";
import Request from "./button/Request";

function Header() {
     return (
          <Navbar expand="xl" className="bg-body-white">
               <Container>
                    <Navbar.Brand className="p-0">
                         <NavLink to="/">
                              <img src={logo} className="d-inline-block align-top" alt="logo" />
                         </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                         <Nav className="m-auto my-2 my-lg-0" navbarScroll>
                              <NavLink to="/" className="nav-link">
                                   Home
                              </NavLink>
                              <NavLink className="nav-link" to="/about" >
                                   About Us
                              </NavLink>
                              <NavLink className="nav-link" to="/product" >
                                   Product
                              </NavLink>
                              <NavLink className="nav-link" to="/mobile-app" >
                                   Mobile App
                              </NavLink>
                              <NavLink to="/pricing" className="nav-link">
                                   Pricing
                              </NavLink>
                         </Nav>
                         <div className="d-flex">
                              <Link to="https://staging-cabinet.trackmydoc.com/login" className="btn btn-outline-primary mr-20">
                                   Login
                              </Link>
                              <Request />
                         </div>
                    </Navbar.Collapse>
               </Container>
          </Navbar>
     );
}
export default Header;
