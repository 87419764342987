import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import SecureMessagesicon from "../assets/img/SecureMessages.svg";
import clienttask from "../assets/img/clienttask.svg";

import messagingcapabilities from "../assets/img/messaging-capabilities.svg";

import customizableuser from "../assets/img/customizable-user.svg";
import { Link } from "react-router-dom";

function SecureMessages() {
     return (
          <div>
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={5}>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">Secure Messages & Client Tasks</h2>
                                        <p className="mb-60">
                                             Connect with your clients directly and securely within your workflow with Trackmydoc’s
                                             efficient and modernized integrated messaging feature. TrackmyDoc is a highly secure, efficient
                                             platform designed for CPA, Insurance Agent, and Immigration Consultant that enables professionals to chat in real
                                             time, upload or scan documents, request information easily, and automatically send reminders to
                                             clients to complete tasks. Leverage the best digital tax preparation tools, e-signature tax
                                             software, tax preparation software, and more—all under one umbrella of customized Cloud CRM
                                             solutions.
                                        </p>
                                        <div className="d-flex">
                                             <Link to="/contact" className="btn btn-primary">
                                                  Request Demo
                                             </Link>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={1}></Col>
                              <Col lg={6}>
                                   <div className="hero-picture">
                                        <img src={SecureMessagesicon} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">
                                                  Let clients communicate with you on the go
                                             </span>
                                             <h2 className="mb-20">Messaging capabilities integrated with a client portal</h2>
                                             <p className="mb-60">
                                                  With incredibly versatile messaging capabilities integrated into the client portal,
                                                  TrackmyDoc offers the easiest and safest ways to communicate and share documents. Do all
                                                  this and more through your client portal or mobile devices.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>A client portal with built-in chats</Accordion.Header>
                                                       <Accordion.Body>
                                                            With its AI-powered CRM platform, TrackmyDoc offers new-age features that
                                                            enhance client relationships. With the best CRM software and client management
                                                            tools, clients can view your messages immediately in their client portal on
                                                            desktop or native iOS and Android apps. Show message content in email
                                                            notifications or only in the portal, based on your preferences.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Send instant messages from mobile devices</Accordion.Header>
                                                       <Accordion.Body>
                                                            Here’s a modern way for your clients to communicate with your team while they’re
                                                            out and about with our native iOS and Android client mobile apps. In addition,
                                                            the web-based client portal offers seamless communication and enables instant
                                                            messaging from mobile devices. TrackmyDoc’s Cloud CRM solutions offer companies
                                                            and clients native apps to communicate on the go.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Attach links, images, and videos</Accordion.Header>
                                                       <Accordion.Body>
                                                            If you want to add visuals to your messages for clients, do it innovatively and
                                                            seamlessly with our native iOS and Android client mobile apps. With the power of
                                                            TrackmyDoc, include files and videos in the chat when it’s easier to show than
                                                            tell and communicate more effectively and quickly.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={messagingcapabilities} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <div className="customizable-user">
                              <img src={customizableuser} />
                              <h4 className="mb-60">
                                   Our clients love the portal. And we love the messages feature where you can send direct messages to the
                                   client.
                              </h4>
                              <div className="mb-0 sub-div">
                                   <h5 className="me-4 mb-0">Kristin Gravitt</h5>Partner at Fox Gravitt, PLLC
                              </div>
                         </div>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">
                                                  Fast way to communicate with clients
                                             </span>
                                             <h2 className="mb-20">Client tasks that help move projects along</h2>
                                             <p className="mb-60">
                                                  Streamline collaboration and keep projects moving forward with easy, secure chat
                                                  workflows.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Client tasks</Accordion.Header>
                                                       <Accordion.Body>
                                                            No more illegible documents: your clients can easily scan and share
                                                            high-resolution collated PDFs directly from their mobile phones.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Integrated with mobile scanner</Accordion.Header>
                                                       <Accordion.Body>
                                                            No more illegible documents: your clients can easily scan and share
                                                            high-resolution collated PDFs directly from their mobile phones.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Preset templates and shortcodes</Accordion.Header>
                                                       <Accordion.Body>
                                                            No more illegible documents: your clients can easily scan and share
                                                            high-resolution collated PDFs directly from their mobile phones.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Incorporated into your workflow</Accordion.Header>
                                                       <Accordion.Body>
                                                            No more illegible documents: your clients can easily scan and share
                                                            high-resolution collated PDFs directly from their mobile phones.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={clienttask} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default SecureMessages;
