import React from "react";
import crmapp from "../../assets/img/crmapp.svg";
import appstore1 from "../../assets/img/app-store-1.png";
import googleplay1 from "../../assets/img/google-play-1.png";
import clientmobileapp from "../../assets/img/client-mobile-app.svg";
import Request from "../button/Request";
import FaqSection from "../Accordion/Accordion";

function Integration() {
  const FAQData = [
    {
      question: "Store client and prospect information",
      answer: "Gather the best-known insights about your clients and leverage that data to serve them better. Empower yourself with the best CRM for small businesses and world-class CRM solutions and get all client information in one central hub,enhancing client servicing."
    },
    {
      question: "Simplified account management",
      answer: "Bring your company’s leads and clients on a single dashboard and configure your CRM hub with client-centric workflows, unlimited contacts, custom fields, tags, filters, bulk actions, and more. With an AI-powered CRM platform, establish a common link between your contacts and accounts to build and track relationships."
    },
    {
      question: "End-to-end management control",
      answer: "TrackmyDoc is your single point of organization and automation for accounting, payroll, and bookkeeping processes.Get client communication, client intake process, creation of tasks and jobs on a preset schedule, billing, engagement letters, custom CRM templates, and CRM input, all done swiftly and flawlessly with little intervention needed."
    },
    {
      question: "Onboard clients in a snap",
      answer: "Use the best CRM software to save time for your team and efficiently serve more clients. With the help of AI-powered and automated CRM platforms, facilitate easy and new client onboarding. From capturing a lead through your personalized login page to e-signing ready files, make it all simple and quick."
    },
    {
      question: "Automated reminders",
      answer: " Automate reminders to keep your clients on track without any manual intervention with the help of TrackmyDoc. Forget manual follow-ups, and with automation, quickly remind clients about pending organizers, invoices, contracts, chat messages, approvals, signatures, and file requests. With efficient e-signature tax software, billing and invoicing software, recurring invoice solutions, and more, save hours of manual work while nurturing client engagement."
    },
  ]
  return (
    <>
      <div className="hero-section common-padding">
        <div className="website-container">
          <div className="block lg:flex gap-5 align-items-center">
            <div className="__text res-mb-40 w-full lg:w-1/2">
              <h2 className="mb-30">CRM & Apps for Your Firm and Clients</h2>
              <p className="mb-3">
                With remote workforce culture on the rise worldwide, there is an increasing need for the right tools to empower business
                owners and enable seamless operations outside the office. Our cloud-based web access platforms empower CPA, Insurance Agent, and
                Immigration Consultant to help them manage their businesses efficiently. Solid CRM software solutions for Native iOS and Android app
                owners accelerate business results by overseeing workflows and access keys.
              </p>
              <p>
                Leverage the benefits of collaboration with our CRM software and a secure mobile app (available on iOS and Android) as an
                excellent tool for clients to sign documents, review proposals, upload files (with a built-in scanner), and more from
                their choice of devices.
              </p>
              <div className="d-flex mt-10">
                <Request />
              </div>
            </div>
            <div className="hero-picture text-center w-full lg:w-1/2">
              <img alt="Product overview" src={crmapp} />
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin">
        <div className="website-container">
          <div className="block lg:flex gap-5 align-items-center">
            <div className="bg-color radius-24 w-full lg:w-1/2 mb-5 lg:mb-0">
              <div className="p-4 p-xl-5 pb-0 pb-lg-0">
                <h3 className="mb-12 secondary-color">Client mobile app</h3>
                <p className="mb-60">
                  With the native iOS and Android apps, enable your clients to interact with your firm from any location. Using their
                  phones, clients can scan, upload, e-sign and approve documents, complete organizers, securely pay invoices and send you
                  messages from the client portal or the mobile app.
                </p>
                <div className="text-center">
                  <img alt="client mobile app" src={clientmobileapp} />
                </div>
              </div>
            </div>
            <div className="_text res-mb-40 w-full lg:w-1/2">
              <span className="mb-12 primary-color fw-500 d-block">Mobility, security and transparency</span>
              <h2 className="mb-20">Access Trackmydoc from anywhere</h2>
              <p className="mb-60">
                We value your convenience and time as much as you do. Use Trackmydoc while you commute and have uninterrupted access to
                all workflows from any device, anywhere, anytime.
              </p>
              <div className="d-flex">
                <a className="me-3">
                  <img alt="google play" src={googleplay1} />
                </a>
                <a>
                  <img alt="app store" src={appstore1} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FaqSection data={FAQData} subTitle='Your concerns, answered' Title='Frequently asked questions' />
    </>
  );
}

export default Integration;
