import React from "react";
import solepractitionerlogo from "../../assets/img/sole-practitioner.svg";
import midsizedlargefirmslogo from "../../assets/img/mid-sized-large-firms.svg";
import enterpriselogo from "../../assets/img/enterprise.svg";
import accountantslogo from "../../assets/img/accountants.svg";
import bookkeeperslogo from "../../assets/img/bookkeepers.svg";
import taxpreparerslogo from "../../assets/img/tax-preparers.svg";
import capterralogo from "../../assets/img/trusted/trusted-1-1.png";
import happiestuserlogo from "../../assets/img/trusted/trusted-2-1.png";
import qualitychoicelogo from "../../assets/img/trusted/trusted-3-1.png";
import trustedvendorlogo from "../../assets/img/trusted/trusted-4-1.png";
import getapp1logo from "../../assets/img/trusted/trusted-5-1.png";
import anualreportlogo from "../../assets/img/anual-report.svg";
import irslogo from "../../assets/img/integration/integration-1.png";
import danskelogo from "../../assets/img/integration/integration-2.png";
import talouslogo from "../../assets/img/integration/integration-3.png";
import ecflogo from "../../assets/img/integration/integration-4.png";
import ctplogo from "../../assets/img/integration/integration-5.png";
import naealogo from "../../assets/img/integration/integration-6.png";
import ifeclogo from "../../assets/img/integration/integration-7.png";
import howtrackmydochelpedlogo from "../../assets/img/leading-firm.png";
import ContactUs from "../ContectUs";
import Request from "../button/Request";

function About() {
  return (
    <>
      <div className="website-container">
        <div className="anual-report common-margin">
          <div className="grid gap-4 justify-items-end items-center lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
            <div className="__text res-mb-40">
              <h2 className="mb-20">
                Product <span className="primary-color">Overview</span>
              </h2>
              <p className="mb-20">
                A one-stop solution for CPA, Insurance Agent, and Immigration Consultant the best CRM software and Cloud CRM solutions to seamlessly
                coordinate with clients and process workflows efficiently. Empower and manage teams with an AI-powered CRM platform.
              </p>
              <p>
                Automate every task with the powerful time-tracking software from TrackmyDoc to streamline operations, enhance
                productivity, and reduce costs. Ensure timely payments and recurring invoices with recurring invoice solutions for best
                outcomes
              </p>
              <Request />
            </div>
            <div className="w-fit">
              <img src={anualreportlogo} />
            </div>
          </div>
        </div>
      </div>

      <div className="customization common-padding common-margin">
        <div className="website-container">
          <h2 className="text-center mb-80 pt-16">Why Choose Us? The Benefits of Our Platform</h2>
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">

            <div className="box">
              <div className="d-flex mb-3">
                <div className="icon d-flex me-3">
                  <img src={solepractitionerlogo} />
                </div>
                <h5 className="mb-0">CRM for client-facing teams</h5>
              </div>
              <p className="box-min-height-120">
                Manage your clients seamlessly, seize the right leads, and keep all records of your contacts and account information
                well-organized. In addition, CRM software enables you to collect payments, interact with clients, and gain insights into
                your team's performance, helping you grow revenue.
              </p>
            </div>

            <div className="box">
              <div className="d-flex mb-3">
                <div className="icon d-flex me-3">
                  <img src={midsizedlargefirmslogo} />
                </div>
                <h5 className="mb-0">Store client information</h5>
              </div>
              <p className="box-min-height-120">
                Gather the best-known insights about your clients and leverage that data to serve them better. Empower yourself with the
                best CRM for small businesses and world-class CRM solutions and get all client information in one central hub, enhancing
                client servicing.
              </p>
            </div>

            <div className="box">
              <div className="d-flex mb-3">
                <div className="icon d-flex me-3">
                  <img src={enterpriselogo} />
                </div>
                <h5 className="mb-0">Simplified account management</h5>
              </div>
              <p className="box-min-height-120">
                Bring your company’s leads and clients on a single dashboard and configure your CRM hub with client-centric workflows,
                unlimited contacts, custom fields, filters, and more. With us, establish a common link between your contacts and accounts
                to build and track relationships.
              </p>
            </div>

            <div className="box">
              <div className="d-flex mb-3">
                <div className="icon d-flex me-3">
                  <img src={accountantslogo} />
                </div>
                <h5 className="mb-0">End-to-end management control</h5>
              </div>
              <p className="box-min-height-120">
                TrackmyDoc is your single point of organization and automation for accounting, payroll, and bookkeeping processes. Get
                client communication, client intake process, creation of tasks and jobs on a preset schedule, billing. all done swiftly
                and flawlessly with little intervention needed.
              </p>
            </div>

            <div className="box">
              <div className="d-flex mb-3">
                <div className="icon d-flex me-3">
                  <img src={bookkeeperslogo} />
                </div>
                <h5 className="mb-0">Onboard clients in a snap</h5>
              </div>
              <p className="box-min-height-120">
                Use the best CRM software to save time for your team and efficiently serve more clients. With the help of AI-powered and
                automated CRM platforms, facilitate easy and new client onboarding. From capturing a lead through your personalized login
                page to e-signing ready files, make it all simple and quick.
              </p>
            </div>

            <div className="box">
              <div className="d-flex mb-3">
                <div className="icon d-flex me-3">
                  <img src={taxpreparerslogo} />
                </div>
                <h5 className="mb-0">Automated reminders</h5>
              </div>
              <p className="box-min-height-120">
                Automate reminders to keep your clients on track without any manual intervention with the help of TrackmyDoc. Forget
                manual follow-ups, and with automation, quickly remind clients about pending organizers, invoices, contracts, chat
                messages, approvals, signatures, and file requests.
              </p>
            </div>

          </div>
        </div>
      </div>

      <div className="leader-trusted common-margin">
        <div className="website-container">
          <div className="__text text-center mb-80">
            <h2 className="mb-20">
              Discover the Benefits That Set Us Apart
            </h2>
            <p className="mb-0">
              Explore the standout features that make Trackmydoc unique, offering innovation, efficiency, and reliability to enhance your
              experience and success.
            </p>
          </div>
          <div className="trusted-list h-fit">
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 h-fit">
              <div className="box">
                <div className="picture">
                  <img src={capterralogo} draggable={false} />
                </div>
              </div>
              <div className="box">
                <div className="picture">
                  <img src={happiestuserlogo} draggable={false} />
                </div>
              </div>
              <div className="box">
                <div className="picture">
                  <img src={qualitychoicelogo} draggable={false} />
                </div>
              </div>
              <div className="box">
                <div className="picture">
                  <img src={trustedvendorlogo} draggable={false} />
                </div>
              </div>
              <div className="box">
                <div className="picture">
                  <img src={getapp1logo} draggable={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin how-trackmydoc">
        <div className="website-container">
          <div className="block lg:flex">
            <div className="__text res-mb-40">
              <h2>How Trackmydoc Revolutionized Operations for a Leading Firm ?</h2>
              <p className="">
                <b className="secondary-color">TrackMyDoc</b> transformed operations for a leading firm by streamlining document
                management, enhancing efficiency, and ensuring secure, real-time access to critical files. With automated workflows,
                seamless collaboration, and robust tracking features, the firm reduced paperwork, minimized errors, and improved overall
                productivity
              </p>
              <p className="mb-30">
                The platform’s intuitive interface and advanced security measures enabled employees to manage documents effortlessly,
                saving time and resources. By integrating TrackMyDoc, the firm achieved greater transparency, compliance, and operational
                excellence, revolutionizing the way they handled documentation and business processes.
              </p>

              <Request />
              <div className="mt-50">
                <div className="mx-700">
                  <div className="counting-box text-center w-100">
                    <div className="flex items-center justify-around">
                      <div>
                        <h4 className="primary-color fw-700">500+</h4>
                        <p className="mb-0">Clients</p>
                      </div>
                      <div>
                        <h4 className="primary-color fw-700">25+</h4>
                        <p className="mb-0">Team Members</p>
                      </div>
                      <div>
                        <h4 className="primary-color fw-700">4</h4>
                        <p className="mb-0">Offices</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img src={howtrackmydochelpedlogo} alt="How Trackmydoc helped" />
            </div>
          </div>
        </div>
        <div className="website-container">
          <div className="integrations-section">
            <h2 className="mb-60 text-center">Seamless Connectivity: Effortless Third-Party Integrations</h2>
            <div className="flex flex-col gap-4 items-center">
              <div className="grid grid-cols-1 gap-5 lg:grid-cols-4 lg:px-20 md:grid-cols-2 md:px-10 sm:px-3 max-sm:px-0">
                <div className="shadow-box shadow-0">
                  <a className="_picture d-block text-center">
                    <img src={irslogo} alt="irs" />
                  </a>
                </div>
                <div className="shadow-box shadow-0">
                  <a className="_picture d-block text-center">
                    <img src={danskelogo} alt="danske" />
                  </a>
                </div>
                <div className="shadow-box shadow-0">
                  <a className="_picture d-block text-center">
                    <img src={talouslogo} alt="talous" />
                  </a>
                </div>
                <div className="shadow-box shadow-0">
                  <a className="_picture d-block text-center">
                    <img src={ecflogo} alt="ecf" />
                  </a>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="grid grid-cols-1 w-full gap-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2">
                  <div className="shadow-box shadow-0">
                    <a className="_picture d-block text-center">
                      <img src={ctplogo} alt="ctp" />
                    </a>
                  </div>
                  <div className="shadow-box shadow-0">
                    <a className="_picture d-block text-center">
                      <img src={naealogo} alt="naea" />
                    </a>
                  </div>
                  <div className="shadow-box shadow-0">
                    <a className="_picture d-block text-center">
                      <img src={ifeclogo} alt="ifec" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContactUs />
    </>
  );
}

export default About;
