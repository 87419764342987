// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import crmapp from "../assets/img/crmapp.svg";
import effectiveclientinteractions from "../assets/img/effective-client-interactions.svg";

import laptop1 from "../assets/img/laptop-1.svg";
import device1 from "../assets/img/device-1.svg";

import clientmobileapp from "../assets/img/client-mobile-app.svg";

import appstore1 from "../assets/img/app-store-1.png";
import googleplay1 from "../assets/img/google-play-1.png";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";

import esignact from "../assets/img/e-sign-act.svg";
import makeesigning from "../assets/img/make-e-signing.svg";

import filtericon from "../assets/img/filter-icon.svg";
import securitytimeicon from "../assets/img/security-time-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";

import quote from "../assets/img/quote.svg";
import staremptybig from "../assets/img/star-empty-big.svg";
import starfillbig from "../assets/img/star-fill-big.svg";
import testimonials1 from "../assets/img/testimonials-1.svg";
import { Link } from "react-router-dom";

function CRMApp() {
     const settings = {
          dots: true,
          arrows: false,
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "0",
          slidesToShow: 3,
          speed: 500,
          responsive: [
               {
                    breakpoint: 1025,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 991,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 767,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 480,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
          ],
     };

     return (
          <div>
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row xs={1} md={1} lg={2} className="align-items-center">
                              <Col>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-30">CRM & Apps for Your Firm and Clients</h2>
                                        <p className="mb-3">
                                             With remote workforce culture on the rise worldwide, there is an increasing need for the right
                                             tools to empower business owners and enable seamless operations outside the office. Our
                                             cloud-based web access platforms empower CPA, Insurance Agent, and Immigration Consultant to help them manage
                                             their businesses efficiently. Solid CRM software solutions for Native iOS and Android app
                                             owners accelerate business results by overseeing workflows and access keys.
                                        </p>
                                        <p>
                                             Leverage the benefits of collaboration with our CRM software and a secure mobile app (available
                                             on iOS and Android) as an excellent tool for clients to sign documents, review proposals,
                                             upload files (with a built-in scanner), and more from their choice of devices.
                                        </p>
                                        <div className="d-flex mt-10">
                                             <Link to="/contact" className="btn btn-primary">
                                                  Request Demo
                                             </Link>
                                        </div>
                                   </div>
                              </Col>
                              <Col>
                                   <div className="hero-picture text-center">
                                        <img alt="Product overview" src={crmapp} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="me-xl-5">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Grow with Trackmydoc</span>
                                             <h2 className="mb-20">The ideal CRM software for client-facing teams</h2>
                                             <p className="mb-0">
                                                  Manage your clients seamlessly, seize the right leads, and keep all records of your
                                                  contacts and account information well-organized. In addition, CRM software enables you to
                                                  collect payments, interact with clients, and gain insights into your team's performance,
                                                  helping you grow revenue.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Store client and prospect information</Accordion.Header>
                                                       <Accordion.Body>
                                                            Gather the best-known insights about your clients and leverage that data to
                                                            serve them better. Empower yourself with the best CRM for small businesses and
                                                            world-class CRM solutions and get all client information in one central hub,
                                                            enhancing client servicing.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Simplified account management</Accordion.Header>
                                                       <Accordion.Body>
                                                            Bring your company’s leads and clients on a single dashboard and configure your
                                                            CRM hub with client-centric workflows, unlimited contacts, custom fields, tags,
                                                            filters, bulk actions, and more. With an AI-powered CRM platform, establish a
                                                            common link between your contacts and accounts to build and track relationships.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>End-to-end management control</Accordion.Header>
                                                       <Accordion.Body>
                                                            TrackmyDoc is your single point of organization and automation for accounting,
                                                            payroll, and bookkeeping processes. Get client communication, client intake
                                                            process, creation of tasks and jobs on a preset schedule, billing, engagement
                                                            letters, custom CRM templates, and CRM input, all done swiftly and flawlessly
                                                            with little intervention needed.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Onboard clients in a snap</Accordion.Header>
                                                       <Accordion.Body>
                                                            Use the best CRM software to save time for your team and efficiently serve more
                                                            clients. With the help of AI-powered and automated CRM platforms, facilitate
                                                            easy and new client onboarding. From capturing a lead through your personalized
                                                            login page to e-signing ready files, make it all simple and quick.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="4">
                                                       <Accordion.Header>Automated reminders</Accordion.Header>
                                                       <Accordion.Body>
                                                       Automate reminders to keep your clients on track without any manual intervention with the help of TrackmyDoc. Forget manual follow-ups, and with automation, quickly remind clients about pending organizers, invoices, contracts, chat messages, approvals, signatures, and file requests. With efficient e-signature tax software, billing and invoicing software, recurring invoice solutions, and more, save hours of manual work while nurturing client engagement.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-850 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={laptop1} />
                                             <img className="second-img" src={device1} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin  common-padding bg-color">
                    <div className="website-container">
                         <div className="text-center">
                              <h2 className="mb-20">Our Testimonials</h2>
                              <p className="mb-60">We have Worked with thousands of amazing people</p>
                         </div>
                         <div className="row">
                              <div className="col-md-12">
                                   <div className="slider-container mb-60">
                                        <Slider {...settings}>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </Slider>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="12">
                                   <div className="_text text-center mb-60 mx-1020">
                                        <span className="mb-12 primary-color fw-500 d-block">Automation and flexibility</span>
                                        <h2 className="mb-20">Automated workflows built for your firm's unique processes</h2>
                                        <p className="mb-0">
                                             Focus on delivering impeccable client service and increase billable hours by automating
                                             repetitive, low-value tasks. Automate your business operations: client onboarding, accounting,
                                             tax prep, bookkeeping and payroll workflows with predefined client and internal team actions.
                                        </p>
                                   </div>
                              </Col>
                              <Col lg="12">
                                   <Row>
                                        <Col xs={12} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>2.5M+</h4>
                                                  <p className="mb-0">Total automation sent in 2023</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>1.5M+</h4>
                                                  <p className="mb-0">Email and secure chat automation sent</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>200,000+</h4>
                                                  <p className="mb-0">Proposals sent automatically</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>200,000+</h4>
                                                  <p className="mb-0">Invoice generated automatically</p>
                                             </div>
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24 res-mb-40">
                                        <div className="text-center p-5">
                                             <img src={effectiveclientinteractions} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="_text res-mb-40 ms-xl-4">
                                        <span className="mb-12 primary-color fw-500 d-block">Effective client interactions</span>
                                        <h2 className="mb-20">
                                             One secure destination for all client communication, including SMS, email sync, chats, and
                                             more.
                                        </h2>
                                        <p className="mb-50">
                                             TrackmyDoc enables safe and secure one-point storage of the entire history of your company's
                                             client interactions. All records are safely stored in a single email, SMS, and interactive chat
                                             feed. With this consolidated approach to interaction and record storage, an automated CRM
                                             system can be used to personalize at scale without risk. Leverage TrackmyDoc's client
                                             management tools to enhance client onboarding with automated, tailored emails and messages that
                                             help engage and convert leads into customers across the journey.
                                        </p>
                                        <ul className="list-50 checked-icon list-ui">
                                             <li>SMS to reach clients instantly</li>
                                             <li>Email sync with no CCs, BCCs, and forwards</li>
                                             <li>Chats to connect with clients in real-time</li>
                                             <li>Client tasks to get everything done on time</li>
                                             <li>Inbox+ to track activity in your firm</li>
                                             <li>Auto-save attachments to the client folder</li>
                                             <li>Media viewer to send images, embed videos</li>
                                        </ul>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="5">
                                   <div className="_text res-mb-40">
                                        <span className="mb-12 primary-color fw-500 d-block">Mobility, security and transparency</span>
                                        <h2 className="mb-20">Access Trackmydoc from anywhere</h2>
                                        <p className="mb-60">
                                             We value your convenience and time as much as you do. Use Trackmydoc while you commute and have
                                             uninterrupted access to all workflows from any device, anywhere, anytime.
                                        </p>
                                        <div className="d-flex">
                                             <a className="me-3">
                                                  <img alt="google play" src={googleplay1} />
                                             </a>
                                             <a>
                                                  <img alt="app store" src={appstore1} />
                                             </a>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="1"></Col>
                              <Col lg="6">
                                   <div className="p-5 pb-0 bg-color radius-24">
                                        <h4 className="mb-12 secondary-color">Client mobile app</h4>
                                        <p>
                                             With the native iOS and Android apps, enable your clients to interact with your firm from any
                                             location. Using their phones, clients can scan, upload, e-sign and approve documents, complete
                                             organizers, securely pay invoices and send you messages from the client portal or the mobile
                                             app.
                                        </p>
                                        <div className="text-center">
                                             <img alt="client mobile app" src={clientmobileapp} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="min-height-850 bg-color radius-24 res-mb-40">
                                        <div className="text-center p-5">
                                             <img src={laptop1} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="ms-xl-5">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Simplify your document management</span>
                                             <h2 className="mb-20">Trackmydoc for Windows</h2>
                                             <p className="mb-0">
                                                  When you have documents on your computer, you can easily upload them directly to
                                                  Trackmydoc using a Windows desktop app and a mapped virtual drive. Their rich capabilities
                                                  allow you to link documents to jobs, lock documents to invoices, and request client
                                                  feedback and e-signatures from the Windows desktop app.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Upload to Trackmydoc with one click</Accordion.Header>
                                                       <Accordion.Body>
                                                            Lock a document to an invoice: the client knows it's available and cannot view
                                                            or download the document until they make a payment. Once the client pays, the
                                                            document automatically becomes accessible to them.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Lock to invoice directly from the tax program</Accordion.Header>
                                                       <Accordion.Body>
                                                            Lock a document to an invoice: the client knows it's available and cannot view
                                                            or download the document until they make a payment. Once the client pays, the
                                                            document automatically becomes accessible to them.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>
                                                            View and edit documents directly from Windows Explorer
                                                       </Accordion.Header>
                                                       <Accordion.Body>
                                                            Lock a document to an invoice: the client knows it's available and cannot view
                                                            or download the document until they make a payment. Once the client pays, the
                                                            document automatically becomes accessible to them.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Upload 8879s and request e-signatures</Accordion.Header>
                                                       <Accordion.Body>
                                                            Lock a document to an invoice: the client knows it's available and cannot view
                                                            or download the document until they make a payment. Once the client pays, the
                                                            document automatically becomes accessible to them.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="4">
                                                       <Accordion.Header>Print directly to Trackmydoc with ease</Accordion.Header>
                                                       <Accordion.Body>
                                                            Lock a document to an invoice: the client knows it's available and cannot view
                                                            or download the document until they make a payment. Once the client pays, the
                                                            document automatically becomes accessible to them.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Make e-signing easy for clients</span>
                                             <h2 className="mb-20">Unlimited e-signatures integrated with white-labeled client portal</h2>
                                             <p className="mb-0">
                                                  Your clients can review, approve and e-sign documents, all through one intuitive,
                                                  custom-branded client portal.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Sign from any device</Accordion.Header>
                                                       <Accordion.Body>
                                                            Request signatures from one or more individuals. Documents can be easily signed
                                                            by multiple parties including married couples or business partners.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Multiple signers</Accordion.Header>
                                                       <Accordion.Body>
                                                            Request signatures from one or more individuals. Documents can be easily signed
                                                            by multiple parties including married couples or business partners.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-650 bg-color radius-24">
                                        <div className="text-center p-5">
                                             <img src={makeesigning} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding common-margin">
                    <div className="website-container">
                         <div className="text-center mb-80 mx-1020">
                              <h2 className="text-center mb-20">Efficient Document Management </h2>
                              <p>
                                   Securely store, share and manage your firm and client documents within your CRM. Sleek interface and easy
                                   navigation allow you to quickly find necessary documents and work with minimum time and maximum
                                   productivity.
                              </p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Unlimited storage</h5>
                                        </div>
                                        <p className="min-72">Store files without limits and extra costs</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={securitytimeicon} />
                                             </div>
                                             <h5 className="mb-0">Bank-grade security</h5>
                                        </div>
                                        <p className="min-72">Compliant with all industry standards</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={printericon} />
                                             </div>
                                             <h5 className="mb-0">Print to Trackmydoc</h5>
                                        </div>
                                        <p className="min-72">Integrated with any tax software</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={folderopenicon} />
                                             </div>
                                             <h5 className="mb-0">Folder templates</h5>
                                        </div>
                                        <p className="min-72">Organize documents easily</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Built-in DMS</h5>
                                        </div>
                                        <p className="min-72">Link documents to workflow</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">PDF editor</h5>
                                        </div>
                                        <p className="min-72">Built-in and ideal for PDF file management</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Desktop app</h5>
                                        </div>
                                        <p className="min-72">Virtual drive to access and edit files</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">E-signatures</h5>
                                        </div>
                                        <p className="min-72">Unlimited e-sign + KBA, QES available</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Unlimited e-signatures</h5>
                                        </div>
                                        <p className="min-72">E-signatures integrated with the client portal</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">Share with third parties</h5>
                                        </div>
                                        <p className="min-72">Securely share documents with third parties and provide expiring links</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Cloud storage</h5>
                                        </div>
                                        <p className="min-72">Store your practice documents securely, with no limits or additional costs</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">Virtual drive</h5>
                                        </div>
                                        <p className="min-72">Manage all documents, freeing up space on your computer</p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <div className="_text text-center mx-707">
                              <h2 className="mb-20">PDF editor</h2>
                              <p className="mb-0">
                                   Use our built-in PDF editor to customize file, merge, highlight, annotate,leave notes and send fillable
                                   forms to your clients. There is no need to download and upload - everything is auto-saved within
                                   Trackmydoc.
                              </p>
                         </div>
                    </div>
               </div>

               <div className="common-margin common-padding-top">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="text-center p-5 bg-color radius-24 res-mb-40">
                                        <img src={esignact} />
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="_text ms-xl-5 ps-xl-4">
                                        <h2 className="mb-20">Compliant with E-Sign Act and UETA</h2>
                                        <p className="mb-0">
                                             E-signatures are legally binding and accepted in the US, Canada, the UK, EU and many other
                                             countries. Trackmydoc is fully compliant with the requirements of the E-Sign Act and UETA.
                                        </p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default CRMApp;
