import React from "react";

const GrammLeachBliley = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Gramm-Leach-Bliley Compliance</h2>
        <p className="text-[#7D8592] mb-4">
          is committed to protecting the privacy and security of our customers'
          nonpublic personal information (NPI) in compliance with the
          Gramm-Leach-Bliley Act (GLBA). This policy outlines how we collect,
          use, and protect NPI in accordance with GLBA's privacy and security
          provisions.
        </p>
        <p className="text-[#7D8592]">
          By using our services, you agree to our compliance with the GLBA and
          the practices outlined in this policy. If you do not agree with the
          terms, please refrain from using our services.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Information We Collect</h2>
          <div>
            <p>
              In accordance with the GLBA, we collect the following types of
              nonpublic personal information (NPI) from our customers:
            </p>
            <ul>
              <li>
                Personal information such as name, address, and contact details.
              </li>
              <li>
                Financial information including account numbers, balances, and
                transaction history.
              </li>
              <li>
                Information about our customers' transactions with us or others.
              </li>
              <li>
                Other information that may be provided during the course of
                using our services.
              </li>
            </ul>
            <p>
              We may also collect non-personally identifiable information, such
              as usage data and cookies, for operational purposes. However, this
              information is not considered NPI under the GLBA.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Use of Information</h2>
          <div>
            <p>
              We use the nonpublic personal information we collect for the
              following purposes:
            </p>
            <ul>
              <li>To provide services and products requested by customers.</li>
              <li>
                To communicate with customers regarding their accounts,
                products, and services.
              </li>
              <li>To improve our services and offerings.</li>
              <li>
                To comply with legal, regulatory, and contractual obligations.
              </li>
              <li>
                To detect, prevent, and respond to fraud or security incidents.
              </li>
            </ul>
            <p>
              We do not use customer information for any purpose other than
              those outlined in this policy, unless required by law or with the
              customer's consent.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Sharing of Information</h2>
          <div>
            <p>
              We do not share nonpublic personal information with third parties
              except as follows:
            </p>
            <ul>
              <li>
                With affiliates or service providers who assist us in providing
                services to our customers and who are bound by confidentiality
                agreements.
              </li>
              <li>
                With regulatory authorities, government agencies, or law
                enforcement as required by law.
              </li>
              <li>
                To comply with subpoenas, court orders, or legal processes.
              </li>
              <li>
                With third parties for marketing purposes, but only if we have
                obtained the customer's explicit consent in accordance with
                applicable laws.
              </li>
            </ul>
            <p>
              We do not sell, rent, or share NPI with non-affiliated third
              parties for marketing purposes without your consent.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Safeguarding Customer Information</h2>
          <div>
            <p>
              We take the following measures to safeguard the nonpublic personal
              information of our customers:
            </p>
            <ul>
              <li>
                Encryption of sensitive data during transmission and storage.
              </li>
              <li>
                Access controls to ensure that only authorized personnel can
                access NPI.
              </li>
              <li>
                Regular audits and monitoring of our systems for security
                vulnerabilities.
              </li>
              <li>
                Employee training on privacy and data protection best practices.
              </li>
              <li>
                Data retention policies to securely dispose of NPI that is no
                longer needed.
              </li>
            </ul>
            <p>
              While we take reasonable steps to protect your information, no
              security measure is entirely foolproof. We cannot guarantee the
              absolute security of your information.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Customer Rights</h2>
          <div>
            <p>
              Under the GLBA, customers have the following rights regarding
              their nonpublic personal information:
            </p>
            <ul>
              <li>
                The right to access and review the information we hold about
                them.
              </li>
              <li>
                The right to request corrections to any inaccurate information.
              </li>
              <li>
                The right to opt-out of the sharing of their NPI with
                non-affiliated third parties for marketing purposes, where
                applicable.
              </li>
            </ul>
            <p>
              If you wish to exercise any of these rights, please contact us
              using the contact information provided below.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Privacy Notice</h2>
          <div>
            <p>
              We provide a Privacy Notice that explains how we collect, use, and
              share your nonpublic personal information. This notice is
              available to you upon request, and we will provide you with a copy
              of the Privacy Notice whenever there are material changes to our
              practices or at least once a year, as required by the GLBA.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. Compliance with GLBA</h2>
          <div>
            <p>
              is committed to ensuring that our practices comply with the
              Gramm-Leach-Bliley Act (GLBA) and other applicable privacy laws.
              We review and update our policies and procedures regularly to
              ensure compliance and to address new legal and regulatory
              requirements.
            </p>
            <p>
              We maintain a dedicated compliance team to oversee our
              GLBA-related activities and ensure that we meet all regulatory
              obligations regarding the privacy and security of customer
              information.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">8. Changes to this Policy</h2>
          <div>
            <p>
              We reserve the right to update or modify this Gramm-Leach-Bliley
              Compliance Policy at any time. Any changes will be communicated to
              you via email or posted on our website. We encourage you to review
              this policy periodically to stay informed about how we are
              protecting your nonpublic personal information.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">9. Contact Information</h2>
          <div>
            <p>
              If you have any questions or concerns regarding our
              Gramm-Leach-Bliley Compliance Policy or the privacy of your
              nonpublic personal information
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrammLeachBliley;
