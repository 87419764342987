import React from "react";
import PricingTable from "./pricingTable";
import FaqSection from "./Accordion/Accordion";

function Pricing() {
     const FAQData = [
          {
               question: "Store client and prospect information",
               answer: "Gather the best-known insights about your clients and leverage that data to serve them better. Empower yourself with the best CRM for small businesses and world-class CRM solutions and get all client information in one central hub,enhancing client servicing."
          },
          {
               question: "Simplified account management",
               answer: "Bring your company’s leads and clients on a single dashboard and configure your CRM hub with client-centric workflows, unlimited contacts, custom fields, tags, filters, bulk actions, and more. With an AI-powered CRM platform, establish a common link between your contacts and accounts to build and track relationships."
          },
          {
               question: "End-to-end management control",
               answer: "TrackmyDoc is your single point of organization and automation for accounting, payroll, and bookkeeping processes.Get client communication, client intake process, creation of tasks and jobs on a preset schedule, billing, engagement letters, custom CRM templates, and CRM input, all done swiftly and flawlessly with little intervention needed."
          },
          {
               question: "Onboard clients in a snap",
               answer: "Use the best CRM software to save time for your team and efficiently serve more clients. With the help of AI-powered and automated CRM platforms, facilitate easy and new client onboarding. From capturing a lead through your personalized login page to e-signing ready files, make it all simple and quick."
          },
          {
               question: "Automated reminders",
               answer: " Automate reminders to keep your clients on track without any manual intervention with the help of TrackmyDoc. Forget manual follow-ups, and with automation, quickly remind clients about pending organizers, invoices, contracts, chat messages, approvals, signatures, and file requests. With efficient e-signature tax software, billing and invoicing software, recurring invoice solutions, and more, save hours of manual work while nurturing client engagement."
          },
     ]
     return (
          <div className="py-[65px]">
               <div className="website-container">
                    <div className="text-center">
                         <h1 className="text-[#000] text-[40px] text-center max-[425px]:text-[20px]">
                              <span className="text-[#0BAFD4]">⚡ Limited Time Offer</span><br /> First 25 Clients Get 50% OFF!
                         </h1>
                         <p className="text-[18px]">
                              With no complicated tiers, modules, or fees per contact, you can feel
                              <br /> confident you made the right choice.
                         </p>
                    </div>
               </div>

               <PricingTable />

               <FaqSection data={FAQData} subTitle='Your concerns, answered' Title='Frequently asked questions' />
          </div>
     );
}

export default Pricing;
