import React from 'react'
import { Accordion } from 'react-bootstrap'

const FaqSection = ({ data = [], subTitle = '', Title = '', isFaq = false }) => {
    return (
        <div className={`${isFaq || "website-container"}`}>
            <div className={`${isFaq || "common-margin max-w-[1000px] w-full mx-auto"}`}>
                <span className="mb-20 primary-color fw-500 d-block text-center">
                    {subTitle || ""}
                </span>
                <h2 className="text-center mb-60">{Title || ""}</h2>

                <Accordion defaultActiveKey="1">
                    {data.map((values, index) => (
                        <Accordion.Item eventKey={String(index)} key={index}>
                            <Accordion.Header>{values?.question}</Accordion.Header>
                            <Accordion.Body>{values?.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    )
}

export default FaqSection
