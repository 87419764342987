import React from "react";

const ShieldPolicy = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Privacy Shield Compliance</h2>
        <p className="text-[#7D8592] mb-4">
          Trackmydoc, LLC (the "Company") is committed to ensuring the privacy
          and security of your personal data. As part of our ongoing efforts to
          protect your privacy, we comply with the EU-U.S. Privacy Shield
          Framework and the Swiss-U.S. Privacy Shield Frameworks (collectively,
          the "Privacy Shield Frameworks") as set forth by the U.S. Department
          of Commerce regarding the collection, use, and retention of personal
          data transferred from the European Union (EU) and Switzerland to the
          United States.
        </p>
        <p className="text-[#7D8592]">
          Please read these Terms carefully before you begin using the Services.
          By using the Services or signing up for an account, you accept and
          agree to be bound by and comply with these Terms, the Privacy Policy,
          and other applicable policies and terms of the Agreement. If you do
          not agree to the entire Agreement, you may not access or use the
          Services.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Privacy Shield Principles</h2>
          <div>
            <p>
              Trackmydoc adheres to the following principles of the Privacy
              Shield Frameworks:
            </p>
            <ul>
              <li>
                <strong>Notice:</strong> We provide clear and conspicuous notice
                to individuals regarding the purposes for which we collect and
                use personal data.
              </li>
              <li>
                <strong>Choice:</strong> We offer individuals the opportunity to
                opt-out of the collection and use of their personal data for
                purposes that are not directly related to the services we
                provide.
              </li>
              <li>
                <strong>Accountability for Onward Transfer:</strong> We are
                responsible for ensuring that personal data is transferred in
                accordance with the Privacy Shield Principles.
              </li>
              <li>
                <strong>Security:</strong> We implement reasonable safeguards to
                protect personal data from unauthorized access and misuse.
              </li>
              <li>
                <strong>Data Integrity and Purpose Limitation:</strong> We
                ensure that personal data is accurate and used only for the
                purposes for which it was collected.
              </li>
              <li>
                <strong>Access:</strong> We provide individuals with access to
                their personal data and the ability to correct, amend, or delete
                it where necessary.
              </li>
              <li>
                <strong>Recourse, Enforcement, and Liability:</strong> We
                provide mechanisms for individuals to resolve complaints about
                our privacy practices.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Types of Data Collected</h2>
          <div>
            <p>Trackmydoc collects personal data such as:</p>
            <ul>
              <li>
                Name, email address, billing address, phone number, and payment
                details.
              </li>
              <li>
                Other information necessary to provide the services you request.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. How We Use Your Data</h2>
          <div>
            <p>We use the personal data we collect to:</p>
            <ul>
              <li>Provide and manage our services.</li>
              <li>
                Process payments and communicate with you about your account and
                transactions.
              </li>
              <li>Improve the functionality and security of our services.</li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Third-Party Transfers</h2>
          <div>
            <p>
              Trackmydoc may transfer personal data to third-party service
              providers who assist in delivering our services. These service
              providers are obligated to safeguard personal data and process it
              only in accordance with our instructions.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Your Rights</h2>
          <div>
            <p>
              You have the right to access, correct, or delete your personal
              data. You can exercise these rights by contacting us directly at
              [email address]. If you are located in the EU or Switzerland, you
              may also have the right to lodge a complaint with the relevant
              data protection authority.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Dispute Resolution</h2>
          <div>
            <p>
              If you have a complaint regarding our Privacy Shield compliance,
              we encourage you to first contact us at [email address] so we can
              attempt to resolve the issue. If the matter is not resolved
              through our internal processes, you may submit your complaint to
              the American Arbitration Association (AAA) for resolution.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. Changes to This Statement</h2>
          <div>
            <p>
              We may update this Privacy Shield Compliance Statement from time
              to time to reflect changes in our practices or legal obligations.
              Any updates will be posted on our website, and the revised
              statement will be effective as of the date of posting.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">8. Contact Information</h2>
          <div>
            <p>
              For questions or concerns regarding our Privacy Shield compliance,
              please contact:
            </p>
            <p>
              <strong>Trackmydoc, LLC</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShieldPolicy;
