import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SecurityPolicy from "./components/SecurityPolicy";
import GeneralData from "./components/GeneralData";
import DataProcessing from "./components/DataProcessing";
import CookiePolicy from "./components/CookiePolicy";
import SubProcessors from "./components/SubProcessors";
import Accessibility from "./components/Accessibility";
import ShieldPolicy from "./components/ShieldPolicy";
import Security from "./components/Security";
import SecurityReview from "./components/SecurityReview";
import RefundPolicy from "./components/RefundPolicy";
import SpamGuidelines from "./components/SpamGuidelines";
import BugBounty from "./components/BugBounty";
import ProgramAgreement from "./components/ProgramAgreement";
import SMSPolicy from "./components/SMSPolicy";
import ReferralProgram from "./components/ReferralProgram";
import GrammLeachBliley from "./components/GrammLeachBliley";
import TrackmydocAcademy from "./components/TrackmydocAcademy";
import TrackmydocWebinars from "./components/TrackmydocWebinars";
import "./style.css";

function Terms() {
     const location = useLocation();
     const [selectedSection, setSelectedSection] = useState("Terms of Service");
     const sections = {
          "Terms of Service": (
               <TermsOfService />
          ),
          "Privacy Policy": (
               <PrivacyPolicy />
          ),
          "Security Policy": (
               <SecurityPolicy />
          ),
          "General Data Protection Regulation (GDPR)": (
               <GeneralData />
          ),
          "Data Processing Amendment": (
               <DataProcessing />
          ),
          "Cookie Policy": (
               <CookiePolicy />
          ),
          "List of Sub-processors": (
               <SubProcessors />
          ),
          "Accessibility Statement": (
               <Accessibility />
          ),
          "Privacy Shield Compliance": (
               <ShieldPolicy />
          ),
          "Security": (
               <Security />
          ),
          "Security Review": (
               <SecurityReview />
          ),
          "Refund Policy": (
               <RefundPolicy />
          ),
          "Spam Guidelines": (
               <SpamGuidelines />
          ),
          "Bug Bounty": (
               <BugBounty />
          ),
          "Partner Program Agreement": (
               <ProgramAgreement />
          ),
          "SMS Policy": (
               <SMSPolicy />
          ),
          "Referral Program Terms": (
               <ReferralProgram />
          ),
          "Gramm-Leach-Bliley Compliance": (
               <GrammLeachBliley />
          ),
          "Trackmydoc Academy Terms of Use": (
               <TrackmydocAcademy />
          ),
          "Trackmydoc webinars and online events: terms of use": (
               <TrackmydocWebinars />
          ),
     };
     // useEffect(() => {
     //      const hash = window.location.hash;
     //      if (hash) {
     //           const element = document.querySelector(hash);
     //           if (element) {
     //                element.scrollIntoView({ behavior: "smooth" });
     //           }
     //      }
     // }, []);
     // console.log(location.state, "[][][][][][][][[[][]][][");
     // useEffect(() => {
     //      if (selectedSection == null) {
     //           setSelectedSection("Terms of Service");
     //      } else {
     //           setSelectedSection(location.state?.change);
     //      }
     // }, []);
     useEffect(() => {
          // If location.state?.change exists, update selectedSection
          if (location.state?.change) {
               setSelectedSection(location.state.change);
          }
     }, [location.state]);

     return (
          <>
               <section className="bg-[#FAFAFA]">
                    <div className="website-container">
                         {/* Left Menu */}
                         <div className="flex flex-important-terms py-[120px] max-[600px]:py-[20px]">
                              <div className="left-menu">
                                   {Object.keys(sections).map((section) => (
                                        // <Link to={`/terms-of-services#${section.replace(/\s+/g, "-").toLowerCase()}`}>
                                        <div
                                             key={section}
                                             className={`menu-item ${selectedSection === section ? "active" : ""}`}
                                             onClick={() => setSelectedSection(section)}
                                        >
                                             {section}
                                        </div>
                                        // </Link>
                                   ))}
                              </div>

                              {/* Right Content */}
                              {/* <div className="right-content">{sections[selectedSection]}</div> */}
                              <div className="right-content">{sections[selectedSection]}</div>
                         </div>
                    </div>
               </section>
          </>
     );
}

export default Terms;
