import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import appstore from "../assets/img/app-store.png";
import googleplay from "../assets/img/google-play.png";

import logo from "../assets/img/logo.png";

import facebook from "../assets/img/facebook.svg";
import twitter from "../assets/img/twitter.svg";
import insta from "../assets/img/insta.svg";
import linkedin from "../assets/img/in.svg";
import { Link, useNavigate } from "react-router-dom";

import call from "../assets/img/call.svg";
import sms from "../assets/img/sms.svg";
import location from "../assets/img/location.svg";
import Request from "./button/Request";

function Footer() {
  const navigate = useNavigate();
  const manageClick = (data) => {
    navigate("/terms-of-services", { state: { change: data } });
    window.scrollTo(0, 0);
  };
  return (
    <footer>
      <div className="website-container">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
          <div>
            <img src={logo} alt="" style={{ height: "60px" }} />
            <div className="app m-0">
              <p className="mt-3 mb-2">Follow us on</p>
              <Col className="text-md-start mt-2">
                <ol className="social-media space-x-2">
                  <li>
                    <a href="https://www.facebook.com/Trackmydoc">
                      <img alt="facebook" src={facebook} />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/">
                      <img alt="twitter" src={twitter} />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/">
                      <img alt="insta" src={insta} />
                    </a>
                  </li>
                  <li>
                    <a href="https://in.linkedin.com/">
                      <img alt="linkedin" src={linkedin} />
                    </a>
                  </li>
                </ol>
              </Col>
            </div>
            <div className="app m-0">
              <p className="mt-3 mb-2">Client mobile app</p>
              <div className="flex gap-3">
                <a className="mb-3 d-block">
                  <img alt="google play" src={googleplay} />
                </a>
                <a className="d-block">
                  <img alt="apps tore" src={appstore} />
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="footer-list">
              <div className="title">Pages</div>
              <div className="list">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/product">Product</Link>
                  </li>
                  <li>
                    <Link to="/mobile-app">Mobile App</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="footer-list">
              <div className="title">Policies</div>
              <div className="list">
                <ul>
                  <li onClick={() => manageClick("Terms of Service")}>
                    <a>Terms of Service</a>
                  </li>
                  <li>
                    <Link to="privacy-policy">Privacy Policy</Link>
                  </li>
                  <li onClick={() => manageClick("Cookie Policy")}>
                    <a>Cookies</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="title">Contact Us</div>
            <div className="space-y-10 mt-3">
              <div className="flex items-center gap-4">
                <img src={call} alt="Call Icon" style={{ background: "white", padding: "3px", borderRadius: "5px" }} />
                <div className="grid">
                  <a href="tel:+1  825 734 8155" className="font-medium max-[425px]:text-[14px] text-white">
                    +1 825 734 8155
                  </a>
                  <a href="tel:+91 815 501 0101" className="font-medium max-[425px]:text-[14px] text-white">
                    +91 815 501 0101
                  </a>
                </div>
              </div>
              <div className="mt-3 flex items-center gap-4 max-[430px]:gap-1">
                <img src={sms} alt="SMS Icon" style={{ background: "white", padding: "3px", borderRadius: "5px" }} />
                <a href="mailto:info@trackmydoc.com" className="font-medium max-[425px]:text-[14px] text-white">
                  info@trackmydoc.com
                </a>
              </div>
              <div className="mt-3 flex items-center gap-4 max-[430px]:gap-1">
                <img src={location} alt="Location Icon" style={{ background: "white", padding: "3px", borderRadius: "5px" }} />
                <a href="https://maps.app.goo.gl/gjTY9ppTvvLNrkxC6" className="font-medium max-[425px]:text-[14px] text-white">
                  Suite 200-V8, 123 Edward St, Torento M5G 1E2
                </a>
              </div>
            </div>
            <Request data={3} />
          </div>
        </div>
      </div>
      <div className="footer-bottom">
          <div className="flex items-center justify-center">
            <div className="grid-cols-12">
              <p className="text-center">Copyright © {new Date().getFullYear()} Trackmydoc. All Rights Reserved.</p>
            </div>
          </div>
      </div>
    </footer>
  );
}
export default Footer;
