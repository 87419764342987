import React from "react";

const SMSPolicy = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">SMS Policy</h2>
        <p className="text-[#7D8592] mb-4">
          This SMS Policy outlines the terms and conditions under which sends
          SMS messages to users, customers, and individuals . By providing your
          phone number to us or by opting into any of our SMS services, you
          consent to receiving SMS communications as described in this Policy.
        </p>
        <p className="text-[#7D8592]">
          Please read this Policy carefully before you provide your phone number
          or opt into any SMS communications. If you do not agree with the terms
          of this Policy, you should not provide your phone number or opt into
          receiving SMS communications.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Consent to Receive SMS</h2>
          <div>
            <p>
              By providing your phone number to us, you consent to receive SMS
              messages from us, including but not limited to:
            </p>
            <ul>
              <li>Promotional messages</li>
              <li>Account updates</li>
              <li>Order confirmations</li>
              <li>Customer service communications</li>
              <li>Transactional messages</li>
            </ul>
            <p>
              You understand that your consent is not a condition of purchasing
              any goods or services from us.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Frequency of SMS Messages</h2>
          <div>
            <p>
              The frequency of SMS messages you receive will depend on your
              interactions with our services. You may receive SMS messages on a
              regular basis, including notifications related to your account,
              purchases, or promotional offers. However, you can opt-out of
              receiving these messages at any time by following the instructions
              provided in the messages.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Opt-Out Procedure</h2>
          <div>
            <p>
              If you no longer wish to receive SMS messages from us, you can
              opt-out at any time by replying "STOP" to any SMS message we send
              you. After opting out, you will no longer receive SMS messages
              unless you opt back in. Standard message and data rates may apply
              when you reply to opt-out.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Message and Data Rates</h2>
          <div>
            <p>
              Message and data rates may apply to any SMS messages you receive
              from us. These rates are determined by your mobile carrier and may
              vary depending on your service plan. Please check with your
              carrier for more information about applicable rates.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Your Responsibilities</h2>
          <div>
            <p>
              By opting into our SMS services, you agree to provide accurate and
              up-to-date information, including your phone number. You are
              responsible for notifying us if your phone number changes. You are
              also responsible for ensuring that your mobile device is capable
              of receiving SMS messages.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Privacy and Data Protection</h2>
          <div>
            <p>
              We are committed to protecting your privacy. Your phone number and
              any other personal information collected in connection with SMS
              communications will be handled in accordance with our Privacy
              Policy. We will not share your phone number with third parties
              without your consent, except as required by law or as necessary to
              provide the services you have requested.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. Compliance with Laws</h2>
          <div>
            <p>
              We comply with all applicable laws and regulations related to SMS
              marketing, including the Telephone Consumer Protection Act (TCPA),
              the CAN-SPAM Act, and other applicable state and federal laws. You
              agree that you will not use our SMS services for any illegal,
              harmful, or unauthorized purposes.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">8. Limitation of Liability</h2>
          <div>
            <p>
              We are not responsible for any delays, failures, or errors in the
              delivery of SMS messages, or for any issues arising from your
              mobile carrier or device. We are also not responsible for any
              charges or fees imposed by your mobile carrier for receiving SMS
              messages.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">9. Changes to the SMS Policy</h2>
          <div>
            <p>
              We reserve the right to modify or update this SMS Policy at any
              time. Any changes will be effective immediately upon posting the
              updated policy on our website or notifying you through SMS. By
              continuing to receive SMS messages from us after any changes to
              this Policy, you agree to the updated terms.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">10. Contact Information</h2>
          <div>
            <p>
              If you have any questions about this SMS Policy or need assistance
              with opting out or managing your SMS preferences
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSPolicy;
