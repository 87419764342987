import React, { useEffect, useState } from "react";
import { PopupModal } from "react-calendly";

const Request = ({data = 0}) => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const handleRequestDemo = () => {
    setIsCalendlyOpen(true);
  };

  const handleClose = () => {
    setIsCalendlyOpen(false);
  };

  return (
    <>
      <button className={`btn btn-primary me-3 mt-${data}`} onClick={handleRequestDemo}>
        Request Demo
      </button>
      {isCalendlyOpen && (
        <PopupModal
          url="https://calendly.com/tech-trackmydoc/30min"
          rootElement={document.getElementById("root")}
          onModalClose={handleClose}
          open={isCalendlyOpen}
        />
      )}
    </>
  );
};

export default Request;
