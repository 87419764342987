import React from "react";

const SubProcessors = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">List of Sub-processors</h2>
        <p className="text-[#7D8592] mb-4">
          This document outlines the sub-processors engaged by Trackmydoc to
          process personal data in connection with the services provided. As
          part of our commitment to data protection, we ensure that all
          sub-processors comply with applicable data protection laws and
          regulations.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Sub-Processor List</h2>
          <div>
            <p>
              This document outlines the sub-processors that Trackmydoc engages
              with to process personal data in connection with the services
              provided. As part of our commitment to data protection, we ensure
              that all sub-processors comply with the applicable data protection
              laws and regulations.
            </p>
            <ul className="list-disc pl-6 mt-4">
              <li>
                <strong>Sub-Processor 1:</strong> <br />
                Name: Demo Sub-Processor Inc.
                <br />
                Service: Cloud Hosting
                <br />
                Purpose: Data storage and processing for user data.
                <br />
                Location: United States
                <br />
                Compliance: GDPR, CCPA
              </li>
              <li>
                <strong>Sub-Processor 2:</strong> <br />
                Name: Demo Payment Processor Ltd.
                <br />
                Service: Payment processing
                <br />
                Purpose: Handling subscription and transaction payments.
                <br />
                Location: United Kingdom
                <br />
                Compliance: PCI-DSS, GDPR
              </li>
              <li>
                <strong>Sub-Processor 3:</strong> <br />
                Name: Demo Analytics Services LLC.
                <br />
                Service: Web analytics
                <br />
                Purpose: Analyzing user behavior on the website.
                <br />
                Location: Germany
                <br />
                Compliance: GDPR
              </li>
              <li>
                <strong>Sub-Processor 4:</strong> <br />
                Name: Demo Email Services Corp.
                <br />
                Service: Email marketing
                <br />
                Purpose: Sending newsletters and promotional emails.
                <br />
                Location: Canada
                <br />
                Compliance: GDPR, CASL
              </li>
            </ul>
            <p className="mt-4">
              We will notify our customers about any changes to this list,
              including the addition of new sub-processors or changes to
              existing sub-processors, in accordance with the terms of our data
              processing agreements.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Sub-Processor Updates</h2>
          <p>
            We will notify our customers about any changes to this list,
            including the addition of new sub-processors or changes to existing
            sub-processors, in accordance with the terms of our data processing
            agreements.
          </p>
        </div>
        <div>
          <h2 className="text-[36px]">3. Data Processing Agreement</h2>
          <p>
            All sub-processors are required to sign a data processing agreement
            (DPA) that ensures compliance with relevant data protection laws,
            including GDPR (if applicable), and outlines the sub-processor’s
            responsibilities for handling personal data.
          </p>
        </div>
        <div>
          <h2 className="text-[36px]">4. Contact Information</h2>
          <p>
            If you have any questions regarding the sub-processor list or data
            processing practices, please contact us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubProcessors;
