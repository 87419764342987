import React from "react";
import "../About/style.css";

function ProductOverview() {
     return (
          <>
               <div className="website-container">
                    <div className="px-16 py-11">
                         <div className="mb-14">
                              <h1 className="text-4xl font-bold mb-4">
                                   Empower Your Practice with TrackmyDoc’s Comprehensive CRM Solutions
                              </h1>
                              <p className="text-lg text-gray-700">
                                   A one-stop solution for CPA, Insurance Agent, and Immigration Consultant the best CRM software and Cloud CRM solutions
                                   to seamlessly coordinate with clients and process workflows efficiently. Empower and manage teams with an
                                   AI-powered CRM platform. Automate every task with the powerful time-tracking software from TrackmyDoc to
                                   streamline operations, enhance productivity, and reduce costs. Ensure timely payments and recurring
                                   invoices with recurring invoice solutions for best outcomes.
                              </p>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Why Choose TrackmyDoc CRM?</h2>
                              <ul className="list-disc list-inside space-y-2 text-gray-700">
                                   <li>
                                        <strong>Seamless Client Coordination:</strong> Enhance your client relationships with a centralized
                                        platform that ensures efficient communication and collaboration.
                                   </li>
                                   <li>
                                        <strong>Workflow Optimization:</strong> Simplify complex processes and improve operational
                                        efficiency with powerful tools and intuitive features.
                                   </li>
                                   <li>
                                        <strong>AI-Powered Insights:</strong> Leverage artificial intelligence to make data-driven decisions
                                        and stay ahead in your field.
                                   </li>
                              </ul>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Features That Transform Your Practice</h2>
                              <div className="space-y-6">
                                   <div>
                                        <h3 className="text-xl font-semibold">1. Advanced Time-Tracking Software</h3>
                                        <p className="text-gray-700">
                                             Automate time management with our powerful time-tracking tools. Monitor tasks, allocate
                                             resources effectively, and ensure every minute is accounted for.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">2. Recurring Invoice Solutions</h3>
                                        <p className="text-gray-700">
                                             Simplify payment processes with recurring invoice management. Automate billing cycles, reduce
                                             manual errors, and ensure timely payments to maintain a healthy cash flow.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">3. Team Management Tools</h3>
                                        <p className="text-gray-700">
                                             Empower your team with a platform that fosters collaboration and accountability. Assign tasks,
                                             track progress, and achieve goals efficiently.
                                        </p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold">4. Workflow Automation</h3>
                                        <p className="text-gray-700">
                                             Eliminate repetitive tasks and focus on what matters most. Automate routine operations to save
                                             time, reduce costs, and boost productivity.
                                        </p>
                                   </div>
                              </div>
                         </div>

                         <div className="mb-14">
                              <h2 className="text-2xl font-semibold mb-4">Benefits of Using TrackmyDoc</h2>
                              <ul className="list-disc list-inside space-y-2 text-gray-700">
                                   <li>
                                        <strong>Enhanced Productivity:</strong> Automate and streamline your operations for maximum
                                        efficiency.
                                   </li>
                                   <li>
                                        <strong>Cost Savings:</strong> Reduce overhead costs with smart tools that eliminate redundant
                                        processes.
                                   </li>
                                   <li>
                                        <strong>Improved Client Satisfaction:</strong> Deliver exceptional service with tools that enhance
                                        communication and accuracy.
                                   </li>
                                   <li>
                                        <strong>Scalable Solutions:</strong> Adaptable to businesses of all sizes, from solo practitioners
                                        to large firms.
                                   </li>
                              </ul>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default ProductOverview;
