import React from "react";

const TrackmydocWebinars = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">
          Trackmydoc Webinars and Online Events: Terms of Use
        </h2>
        <p className="text-[#7D8592] mb-4">
          Welcome to Trackmydoc's Webinars and Online Events! These Terms of Use
          govern your access to and participation in webinars and other online
          events hosted by Trackmydoc . By registering for or attending any
          Webinar or Event, you agree to comply with and be bound by these
          Terms. If you do not agree to these Terms, you may not participate in
          the Webinar or Event.
        </p>
        <p className="text-[#7D8592]">
          Please read these Terms carefully before registering for or attending
          any Webinar or Event. These Terms apply to all participants, including
          attendees, speakers, and any other individuals who access or
          participate in the Webinars or Events.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Registration and Access</h2>
          <div>
            <p>
              To participate in a Trackmydoc Webinar or Event, you must complete
              the registration process by providing accurate and up-to-date
              information. You will be provided with access details, such as
              links and login credentials, after your registration is confirmed.
            </p>
            <p>
              By registering, you agree to receive notifications and updates
              related to the Webinar or Event, including changes to schedules or
              speakers.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Use of Webinars and Event Content</h2>
          <div>
            <p>
              All content presented during Trackmydoc Webinars or Events,
              including but not limited to presentations, videos, documents, and
              other materials, is protected by copyright and intellectual
              property laws. Trackmydoc grants you a limited, non-exclusive,
              non-transferable license to view the Content for personal,
              non-commercial purposes only.
            </p>
            <p>
              You may not record, reproduce, distribute, or create derivative
              works of the Content without prior written permission from
              Trackmydoc.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. User Conduct</h2>
          <div>
            <p>
              By participating in Trackmydoc Webinars or Events, you agree to:
            </p>
            <ul>
              <li>
                Behave respectfully and professionally during the Webinar or
                Event.
              </li>
              <li>
                Not engage in any disruptive behavior that interferes with the
                experience of other participants or speakers.
              </li>
              <li>
                Not share offensive, harmful, or inappropriate content or
                comments during the Webinar or Event.
              </li>
              <li>
                Not record, photograph, or broadcast the Webinar or Event
                without express permission from Trackmydoc.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Event Changes and Cancellations</h2>
          <div>
            <p>
              Trackmydoc reserves the right to modify, reschedule, or cancel any
              Webinar or Event at any time. In the event of a cancellation,
              Trackmydoc will make reasonable efforts to notify registered
              participants and offer alternative options, if applicable.
            </p>
            <p>
              Trackmydoc is not responsible for any costs or damages incurred by
              participants due to event changes or cancellations.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Fees and Payment</h2>
          <div>
            <p>
              Some Webinars or Events may require a registration fee. If a fee
              applies, the payment terms will be clearly outlined during the
              registration process. By registering for a paid event, you agree
              to pay the applicable fee.
            </p>
            <p>
              All payments must be made through the payment methods provided on
              the Trackmydoc platform. If payment is not received, access to the
              Webinar or Event may be denied.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Privacy and Data Protection</h2>
          <div>
            <p>
              Trackmydoc respects your privacy. Any personal information
              collected during the registration process or participation in
              Webinars or Events will be handled in accordance with our Privacy
              Policy.
            </p>
            <p>
              By registering for and participating in a Webinar or Event, you
              consent to the collection, use, and processing of your personal
              information as outlined in the Privacy Policy.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">
            7. Disclaimers and Limitation of Liability
          </h2>
          <div>
            <p>
              Trackmydoc makes no representations or warranties regarding the
              accuracy, completeness, or usefulness of the Content presented
              during the Webinars or Events. The Webinars and Events are
              provided "as is" and without any warranty of any kind, express or
              implied.
            </p>
            <p>
              Trackmydoc is not responsible for any damages, losses, or costs
              incurred as a result of attending or participating in a Webinar or
              Event, including but not limited to direct, indirect, incidental,
              or consequential damages.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">
            8. Recording and Use of Participant Information
          </h2>
          <div>
            <p>
              Trackmydoc may record Webinars and Events for the purpose of
              creating educational content, improving services, or for marketing
              purposes. By participating in a Webinar or Event, you consent to
              being recorded and agree that your name, image, and comments may
              be used in future promotional materials or educational content,
              unless you opt-out in advance.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">9. Governing Law</h2>
          <div>
            <p>
              These Terms are governed by the laws of the jurisdiction in which
              Trackmydoc is located, without regard to its conflict of law
              principles. Any disputes arising from these Terms shall be
              resolved in the appropriate courts located.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">10. Changes to the Terms of Use</h2>
          <div>
            <p>
              Trackmydoc reserves the right to modify or update these Terms at
              any time. Any changes will be posted on the Trackmydoc website,
              and you will be notified of any material changes. By continuing to
              participate in the Webinars or Events after changes are made, you
              agree to be bound by the updated Terms.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">11. Contact Information</h2>
          <div>
            <p>
              If you have any questions or concerns regarding these Terms of Use
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackmydocWebinars;
