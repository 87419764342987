import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import whytrackmydoc from "../assets/img/whytrackmydoc.svg";
import easyintuitive from "../assets/img/easyintuitive.svg";

import nativescanner from "../assets/img/nativescanner.svg";
import esignatures from "../assets/img/e-signatures.svg";
import approvals from "../assets/img/approvals.svg";

import filtericon from "../assets/img/filter-icon.svg";
import securitytimeicon from "../assets/img/security-time-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";

import quote from "../assets/img/quote.svg";
import starempty from "../assets/img/star-empty.svg";
import starfill from "../assets/img/star-fill.svg";
import review1 from "../assets/img/review-1.svg";
import review2 from "../assets/img/review-2.svg";
import review3 from "../assets/img/review-3.svg";
import { Link } from "react-router-dom";

function ClientPortal() {
     return (
          <div>
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={12} xl={4}>
                                   <div className="__text">
                                        <h2 className="mb-20">Client portal & mobile app your clients will love</h2>
                                        <p className="mb-60">
                                             Leverage the all-in-one CRM software designed and curated for CPA, Insurance Agent, and Immigration Consultant to
                                             enhance client interactions while keeping everything organized and secure. Exchange documents,
                                             collect e-signatures, issue invoices, and communicate with your clients effortlessly through an
                                             intuitive, custom-branded client portal and mobile app. Using the best CRM software for small
                                             businesses enhances client interactions while keeping everything organized and secure.
                                        </p>
                                        <div className="d-flex">
                                             <Link to="/contact" className="btn btn-primary">
                                                  Request Demo
                                             </Link>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={0} xl={2}></Col>
                              <Col lg={12} xl={6}>
                                   <ul className="customer-review-box mt-5 mt-xl-0">
                                        <li className="left15">
                                             <div>
                                                  <img src={quote} />
                                                  <p>
                                                       Our company uses Deupload, and I also use Box personally to store my documents,
                                                       photos, videos, sensitive data etc.{" "}
                                                  </p>
                                                  <div className="text-center">
                                                       <div>
                                                            <b>Rodrigo Duarte</b>
                                                       </div>
                                                       <span>Graphic Designer</span>
                                                       <div className="star d-flex justify-content-center">
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starempty} />
                                                       </div>
                                                       <div className="review-img">
                                                            <img src={review1} />
                                                       </div>
                                                  </div>
                                             </div>
                                        </li>
                                        <li className="mtop-190">
                                             <div>
                                                  <img src={quote} />
                                                  <p>
                                                       Our company uses Deupload, and I also use Box personally to store my documents,
                                                       photos, videos, sensitive data etc.{" "}
                                                  </p>
                                                  <div className="text-center">
                                                       <div>
                                                            <b>Rodrigo Duarte</b>
                                                       </div>
                                                       <span>Graphic Designer</span>
                                                       <div className="star d-flex justify-content-center">
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starempty} />
                                                       </div>
                                                       <div className="review-img">
                                                            <img src={review2} />
                                                       </div>
                                                  </div>
                                             </div>
                                        </li>
                                        <li className="right15">
                                             <div>
                                                  <img src={quote} />
                                                  <p>
                                                       Our company uses Deupload, and I also use Box personally to store my documents,
                                                       photos, videos, sensitive data etc.{" "}
                                                  </p>
                                                  <div className="text-center">
                                                       <div>
                                                            <b>Rodrigo Duarte</b>
                                                       </div>
                                                       <span>Graphic Designer</span>
                                                       <div className="star d-flex justify-content-center">
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starempty} />
                                                       </div>
                                                       <div className="review-img">
                                                            <img src={review3} />
                                                       </div>
                                                  </div>
                                             </div>
                                        </li>
                                   </ul>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-diff-margin all-in-one-solution">
                    <div className="website-container">
                         <Row>
                              <Col lg="5">
                                   <div className="bg-color radius-24 res-mb-40">
                                        <div className="combine-picture text-center p-5">
                                             <img src={whytrackmydoc} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="1"></Col>
                              <Col lg="6">
                                   <div className="_text">
                                        <span className="mb-12 fw-500 d-block primary-color">Why Trackmydoc</span>
                                        <h2 className="mb-20">Client portal focused on client experience</h2>
                                        <p className="mb-20">
                                             We make it our mission to make the client experience easy and intuitive, one in which your
                                             clients can access everything in one unified hub for all interactions with your firm. From
                                             completing e-signatures to secure messaging to accepting proposals and more, the intuitive
                                             interface and convenient dashboard ensures your clients stay in the loop and easily access what
                                             you need them to action.
                                        </p>
                                        <p className="mb-0">
                                             Utilizing Trackmydoc as your client portal and mobile app will elevate your brand and reduce
                                             admin costs.
                                        </p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding common-margin">
                    <div className="website-container">
                         <div className="text-center mb-80">
                              <h2 className="text-center mb-20">One portal, all touchpoints of your firm</h2>
                              <p className="mx-1020 mb-0">
                                   Within the context of a job, your firm may want clients to complete an intake form, upload, approve or
                                   e-sign documents and engagement letters, communicate securely, and most importantly, make payment. You
                                   could send your client to 5 different apps with 5 different logins, or, they could do it all in your
                                   custom-branded, white-labeled Trackmydoc portal.
                              </p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Proposals & ELs</h5>
                                        </div>
                                        <p className="min-height-48">Price consistently, remove scope creep and grow your firm</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={securitytimeicon} />
                                             </div>
                                             <h5 className="mb-0">Documents</h5>
                                        </div>
                                        <p className="min-height-48">Unlimited secure storage + mobile scanner</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={printericon} />
                                             </div>
                                             <h5 className="mb-0">Messaging</h5>
                                        </div>
                                        <p className="min-height-48">Message securely from anywhere and on any device</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={folderopenicon} />
                                             </div>
                                             <h5 className="mb-0">Signatures</h5>
                                        </div>
                                        <p className="min-height-48">Unlimited e-signatures + KBA, QES</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Billing</h5>
                                        </div>
                                        <p className="min-height-48">One-time/recurring, via credit cards, ACH, SEPA, BACS</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">Client tasks</h5>
                                        </div>
                                        <p className="min-height-48">Send To-dos, one-time/recurring, with auto-reminders</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Organizers</h5>
                                        </div>
                                        <p className="min-height-48">70% higher completion rate than comparable tools</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">Sleek design</h5>
                                        </div>
                                        <p className="min-height-48">Intuitive, clean, easy to navigate user interface</p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding-top all-in-one-solution">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Easy & intuitive</span>
                                             <h2 className="mb-20">Not all client portals are made equal</h2>
                                             <p className="mb-60">
                                                  The first client portal your clients actually want to use. Built to fit the practice
                                                  management needs of tax, bookkeeping and accounting firms. Moreover, community requests
                                                  drive continuous development and improvement.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Not all client portals are made equal</Accordion.Header>
                                                       <Accordion.Body>
                                                            Built to fit, use TrackmyDoc's client management tools to meet the practice
                                                            management needs of bookkeeping, tax, and accounting firms and grow your
                                                            business effortlessly. TrackmyDoc is the most preferred portal/ platform your
                                                            clients want to use.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Your firm’s branding is at the forefront</Accordion.Header>
                                                       <Accordion.Body>
                                                            Elevate your brand's visibility and performance with TrackmyDoc's brand identity
                                                            and professional website design. Create a secure, easy-to-navigate, and use
                                                            client portal. Our dynamic platform helps you customize your company's URL and
                                                            login page. With our AI-powered CRM platform and automated CRM system,
                                                            personalize automated system emails.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Client tasks and automated reminders</Accordion.Header>
                                                       <Accordion.Body>
                                                            Enhance the way you seek information from your clients. TrackmyDoc’s client
                                                            management tools and CRM software help you generate a user-friendly to-do list
                                                            and automated reminders.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>
                                                            Forms & organizers to gather client data seamlessly
                                                       </Accordion.Header>
                                                       <Accordion.Body>
                                                            With TrackmyDoc you can get the best forms and organizers—one that lets your
                                                            clients enjoy completing. Our robust, intuitive, and easy-to-use platform
                                                            facilitates gathering client data seamlessly on desktop or mobile. The built-in
                                                            AI-powered CRM platform helps returning clients reuse prior responses and thus
                                                            save clicks and hours.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="4">
                                                       <Accordion.Header>Easy and secure access</Accordion.Header>
                                                       <Accordion.Body>
                                                            With our competent automated CRM system, you can provide one-click account
                                                            activation for your clients and keep your team and client data protected via
                                                            two-factor and biometric authentication. Stand out from the competition by
                                                            providing easy and secure access.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={easyintuitive} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution">
                    <div className="website-container">
                         <Row>
                              <Col lg="7">
                                   <div className="_text mb-60">
                                        <span className="mb-12 fw-500 d-block primary-color">Seamless flow</span>
                                        <h2 className="mb-20">Streamlined document management</h2>
                                        <p className="mb-60">
                                             Unlimited secure document storage included. Your clients can access your workspace from
                                             anywhere and upload, approve and e-sign documents in your white-labeled portal. Using the
                                             mobile app, your clients can use the built-in scanner to ensure you have high-quality PDF
                                             source documents
                                        </p>
                                   </div>
                              </Col>
                         </Row>
                         <Row className="">
                              <Col lg="4" className="res-mb-40">
                                   <div className="p-5 pb-0 bg-color radius-24 text-center min-height-557">
                                        <h4 className="mb-12 secondary-color mb-60">Native Scanner</h4>
                                        <div className="text-center pb-60">
                                             <img alt="client mobile app" src={nativescanner} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="4" className="res-mb-40">
                                   <div className="p-5 pb-0 bg-color radius-24 text-center min-height-557">
                                        <h4 className="mb-12 secondary-color mb-60">e-signatures</h4>
                                        <div className="text-center pb-60">
                                             <img alt="client mobile app" src={esignatures} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="4" className="res-mb-40">
                                   <div className="p-5 pb-0 bg-color radius-24 text-center min-height-557">
                                        <h4 className="mb-12 secondary-color mb-60">Approvals</h4>
                                        <div className="text-center pb-60">
                                             <img alt="client mobile app" src={approvals} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="res-mb-40">
                                             <h2 className="mb-20">All communication in one unified workspace</h2>
                                             <p className="mx-1020 mb-0">
                                                  Bring all channels of communication in one modern Trackmydoc workspace. Eliminate insecure
                                                  email boxes and let your team and clients communicate from one centralized environment. On
                                                  mobile or desktop, your team is just one click away.
                                             </p>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <Row>
                                        <Col xs={12} md={6} lg={12} xl={6}>
                                             <div className="box">
                                                  <div className="d-flex mb-3">
                                                       <div className="icon d-flex me-3">
                                                            <img src={solepractitionerlogo} />
                                                       </div>
                                                       <h5 className="mb-0">Notifications</h5>
                                                  </div>
                                                  <p>With the client dashboard, clients will never miss important updates.</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={12} xl={6}>
                                             <div className="box">
                                                  <div className="d-flex mb-3">
                                                       <div className="icon d-flex me-3">
                                                            <img src={filtericon} />
                                                       </div>
                                                       <h5 className="mb-0">Booking calls</h5>
                                                  </div>
                                                  <p>Allow clients to book calls with you directly from the portal.</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={12} xl={6}>
                                             <div className="box">
                                                  <div className="d-flex mb-3">
                                                       <div className="icon d-flex me-3">
                                                            <img src={securitytimeicon} />
                                                       </div>
                                                       <h5 className="mb-0">Chats with client tasks</h5>
                                                  </div>
                                                  <p>As easy as texting via WhatsApp only incorporated into your CRM.</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={12} xl={6}>
                                             <div className="box">
                                                  <div className="d-flex mb-3">
                                                       <div className="icon d-flex me-3">
                                                            <img src={clipboardtexticon} />
                                                       </div>
                                                       <h5 className="mb-0">Email sync</h5>
                                                  </div>
                                                  <p>Every email between client and your team, in one place.</p>
                                             </div>
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default ClientPortal;
