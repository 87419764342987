import React from "react";

const GeneralData = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">
          General Data Protection Regulation (GDPR)
        </h2>
        <p className="text-[#7D8592] mb-4">
          Trackmydoc is committed to ensuring compliance with the General Data
          Protection Regulation (GDPR) and other relevant data protection laws.
          These regulations reinforce individuals' rights to control their
          personal data and impose obligations on organizations that process
          such data. By accessing or using Trackmydoc's Services, you confirm
          your understanding and acceptance of these terms, which form part of
          the Agreement.
        </p>
        <p className="text-[#7D8592]">
          This GDPR compliance policy outlines the roles, responsibilities, and
          requirements for processing personal data within the framework of the
          Agreement. If you disagree with these terms, you are prohibited from
          using the Services.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Definitions</h2>
          <div>
            <p>The accompanying definitions apply to these terms:</p>
            <p>
              «Agreement» signifies these Terms, notwithstanding our Privacy
              Policy, Data Processing Amendment («DPA»), Business Associate
              Agreement («BAA»), Refund Policy, Spam Policy, Partner Program
              Terms and Referral Program Terms, and any other electronic or
              composed agreement essentially between or pertinent to Trackmydoc
              and Customer administering the agreement of the Service to you, as
              corrected every now and then.
            </p>
            <p>
              «Control» signifies, for motivations behind this definition,
              immediate or roundabout proprietorship or control of over half of
              the voting interest of the subject entity.
            </p>
            <p>«Customer» signifies you or your company or employer.</p>
            <p>
              «Data Controller» signifies the regular or lawful individual,
              public authority, office or other body which alone or jointly with
              others decides the reasons and method for the Processing of
              Personal Data. You (or your company or employer) are the Data
              Controller; Trackmydoc isn’t the Data Controller but the Data
              Processor with respect to the Processing of your Personal Data.
            </p>
            <p>
              «Data Processor» signifies the entity that Processes Personal Data
              for the benefit of the Data Controller, including, where
              pertinent, a «service provider» as that term is characterized in
              the CCPA. Trackmydoc implies the Data Processor that has been
              retained by the Data Controller (you or your organization or
              employer) to Process your Personal Data on behalf of the Data
              Controller (Trackmydoc).
            </p>
            <p>
              «Data Protection Laws» manner any and all legal guidelines and
              policies, consisting of legal guidelines and policies of the
              European Union, the European Economic Area and their member
              states, Switzerland, the UK, and the USA and its states, relevant
              to the Processing of Personal Data below the Agreement as amended
              from time to time, consisting of however now no longer constrained
              to EU Data Protection Laws and Non-EU Data Protection Laws
              relevant to the Processing of Personal Data below the Agreement.
            </p>
            <p>
              «Fees» means the fees for provision of the Services as described
              on the Trackmydoc Website or otherwise set out in our Portal,
              including but not limited to Subscription Fees, Additional Client
              Fees, Payment Service Fees (as applicable).
            </p>
            <p>
              «Personal Data» means any information relating to (i) an
              identified or identifiable natural person and, (ii) an identified
              or identifiable legal entity (where such information is protected
              similarly as personal data or personally identifiable information
              under applicable Data Protection Laws).
            </p>
            <p>
              «Process» and «Processing» means any operation or set of
              operations which is performed upon Personal Data, whether or not
              by automatic means, including collection, recording, organization,
              structuring, storage, adaptation or alteration, retrieval,
              consultation, use, disclosure by transmission, dissemination or
              otherwise making available, alignment or combination, restriction,
              erasure or destruction.
            </p>
            <p>
              «Payment Service Fees» means the service fee for the processing of
              payments as described on the Trackmydoc Website or otherwise set
              out in your Portal. «Proposal» refers to a proposal for accounting
              or other professional services (“Professional Services”) that a
              Logged-In User has created through the Services to send to a
              Client.
            </p>
            <p>
              «Service» means the online tax firm management services made
              available (as may be changed or updated from time to time by
              Trackmydoc) via the website.
            </p>
            <p>
              «Website» means the Internet site at the domain www.taxdome.com or
              any other site operated by Trackmydoc.
            </p>
            <p>
              «Trackmydoc» means Trackmydoc, Llc. and all current and future
              global subsidiaries of Trackmydoc Llc.
            </p>
            <p>«TOS» Terms of Service</p>
            <p>
              «You», «Your», or «Customer» refers to anyone accessing or subject
              to the Service.
            </p>
            <p>
              «User» refers to the individual who has created an account to use
              the Services for their own benefit and / or on behalf of a
              company, partnership, association or other legal entity (a “Legal
              Entity”). When a User has created an account to use the Services
              and is logged in, they may be referred to in these TOS as a
              “Logged-in User”. The Legal Entity that the User is representing
              may be referred to in these TOS as a “Professional Services
              Provider”.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">
            2. License for Use of the Service; Identity Authentication
          </h2>
          <div>
            <p>
              The Service is licensed for use only under these Terms. Trackmydoc
              reserves all rights not expressly granted to you, including title
              and exclusive ownership of the Service, any and all software or
              updates thereto, and source code for the Service.
            </p>
            <p>
              Upon registering for the Service, Trackmydoc gives you the right
              to install the Service for use by the total number of Users you
              identify and authorize. The Service may not be used or accessed by
              (a) individuals who are not named individuals; or (b) any other
              software or hardware device that does not require a named
              individual to use or access it. A named individual means an
              individual identified by you by name who is authorized to use the
              Service, regardless of how such access occurs or if such
              individual uses any hardware or software that reduces the apparent
              number of Users who are using the Service, such as by using a
              terminal service. The Service may not be used or accessed by any
              other software or hardware device that does not require an
              individual to use or access it. Trackmydoc reserves the right at
              any time to require you to provide a list of the named
              individual(s).
            </p>
            <p>
              You may not rent, lease, lend, sell, redistribute or sublease the
              Service. These Terms will govern any upgrades provided by
              Trackmydoc that replace and/or supplement the original Service.
              You agree to use your best efforts to protect the Service and
              upgrades from unauthorized use, reproduction, distribution,
              publication, or alteration.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Payment Processing</h2>
          <div>
            <p>
              In order to pay your Subscription Fees through the Services, you
              will be required to enter your first name, last name, email, phone
              number, billing address, credit card number, credit card expiry
              date, and card security code (CVC). This may be referred to in
              these TOS or the Privacy Policy as “Billing Information”.
            </p>
            <p>
              All payments are made using a secure https:// connection, and
              payment processing is handled through the “Third-Party Payment
              Processor” Stripe or CPAcharge, though this is subject to change
              without notice. The Third-Party Payment Processor currently
              accepts certain credit cards as payment options but these are
              subject to change without notice. Once transactions are accepted
              by the Third-Party Payment Processor, they are processed in
              accordance with their program rules and procedures and Terms of
              Service. Trackmydoc and the Third-Party Payment Processor are
              unaffiliated companies and Trackmydoc has no influence on the
              operations of the Third-Party Payment Processor. Trackmydoc and /
              or its Representatives shall in no way be held responsible for any
              losses or damages, direct or indirect, pecuniary or otherwise,
              resulting from any error or failure on the part of the Third-Party
              Payment Processor.
            </p>
            <p>
              All Billing Information is collected by the Third-Party Payment
              Processor, on their own secured servers. Trackmydoc does not have
              access to any credit card information, nor can it be responsible
              for any breach of information caused by faulty programming or
              malicious users on the servers of the Third-Party Payment
              Processor. Non-financial information will, however, be available
              to Trackmydoc for invoice-making and record-keeping purposes.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Term and Fees</h2>
          <div>
            <p>
              Provided prior notice of pricing is given to you, Trackmydoc
              reserves the right to charge you for use of the Service on a
              periodic (e.g., weekly or monthly) basis or otherwise. These Terms
              shall begin upon your registration for the Services and shall
              continue until your use of the Service is terminated by you or by
              Trackmydoc. You are responsible for all fees due to Trackmydoc and
              any compensation due prior to the termination date.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Other Terms</h2>
          <div>
            <p>
              Upon the termination of these Terms, the Service and all updates
              may cease to properly function, and all warranties, express or
              implied, regarding the Service shall terminate. Your rights under
              these terms will automatically terminate without notice from
              Trackmydoc if you fail to comply with any provision of these
              Terms. Further, Trackmydoc may terminate the Service for any
              action taken by you that Trackmydoc believes in its sole
              discretion is an inappropriate use of the Service even if not
              specifically detailed by these Terms, including any use of the
              Service that is prohibited by federal, state, or local law. Any
              warranty regarding the Service will automatically terminate
              without notice if you fail to comply with any provision of these
              Terms. The parties expressly acknowledge and agree that all
              provisions of these Terms that concern Copyright or other
              protectable interests of Trackmydoc shall remain in full force and
              effect notwithstanding termination of any warranty or use of the
              Service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralData;
