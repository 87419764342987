import React from "react";

const SecurityPolicy = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Security Policy</h2>
        <p className="text-[#7D8592] mb-4">
          Trackmydoc provides the Service to you in accordance with and subject
          to the following Terms of Service (these «Terms»). These Terms are
          part of the Agreement and set forth the terms and conditions under
          which you may use the Service in accordance with the Agreement and
          govern your access to and use of all software, mobile apps, websites
          and related services provided to you by Trackmydoc (collectively, the
          «Services»)
        </p>
        <p className="text-[#7D8592]">
          Please read these Terms carefully before you begin using the Services.
          By using the Services or signing up for an account, you accept and
          agree to be bound by and comply with these Terms, the Privacy Policy
          and other applicable policies and terms of the Agreement. If you
          do not agree to the entire Agreement, you may not access or use the
          Services.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <div>
            <h3 className="text-[30px]">1. Purpose</h3>
            <p>
              This Security Policy outlines the framework for ensuring the
              confidentiality, integrity, and availability of Trackmydoc's
              information systems, assets, and data.
            </p>
            <h3 className="text-[30px]">2. Scope</h3>
            <p>
              This policy applies to all employees, contractors, vendors, and
              third parties who access or use Trackmydoc's systems, networks, or
              data.
            </p>
            <h3 className="text-[30px]">3. Responsibilities</h3>
            <p>
              - <strong>Executive Leadership:</strong> Ensure adequate resources
              for implementing and maintaining security measures.
              <br />- <strong>IT Department:</strong> Oversee technical controls
              and monitor compliance.
              <br />- <strong>Employees:</strong> Follow all security policies
              and report incidents.
              <br />- <strong>Third Parties:</strong> Adhere to contractual
              security obligations.
            </p>
            <h3 className="text-[30px]">4. Key Policies</h3>
            <ul className="list-disc pl-6">
              <li>Encrypt sensitive data at rest and in transit.</li>
              <li>
                Enforce role-based access and multi-factor authentication.
              </li>
              <li>
                Maintain an incident response plan for addressing security
                breaches.
              </li>
              <li>Provide regular security training and awareness programs.</li>
            </ul>
            <h3 className="text-[30px]">5. Reporting and Enforcement</h3>
            <p>
              All security incidents or violations must be reported to the
              Trackmydoc Security Team. Violations may result in disciplinary
              action or termination of access.
            </p>
            <h3 className="text-[30px]">6. Policy Review</h3>
            <p>
              This policy will be reviewed annually or when significant changes
              occur in the organization’s infrastructure or regulatory
              environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityPolicy;
