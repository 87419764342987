import React from "react";

const SecurityReview = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Security Review</h2>
        <p className="text-[#7D8592] mb-4">
          This Security Review outlines the security measures and practices we
          employ to ensure that your data and our systems remain secure. The
          review assesses various aspects of our platform, including data
          encryption, access control, incident response, and more. This document
          is part of our commitment to maintaining the highest standards of
          security and ensuring the protection of your personal and financial
          information.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Overview of Security Measures</h2>
          <div>
            <p>
              This section provides an overview of the security measures
              implemented across our platform to protect user data and prevent
              unauthorized access.
            </p>
            <ul>
              <li>
                <strong>Encryption:</strong> All sensitive data is encrypted
                both in transit and at rest using industry-standard encryption
                protocols.
              </li>
              <li>
                <strong>Authentication:</strong> We use strong authentication
                mechanisms, including two-factor authentication (2FA) and strong
                password policies.
              </li>
              <li>
                <strong>Access Control:</strong> We implement role-based access
                control (RBAC) to limit access to sensitive data based on user
                roles and responsibilities.
              </li>
              <li>
                <strong>Regular Audits:</strong> We perform regular security
                audits and vulnerability assessments to identify and address
                potential risks.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Data Encryption</h2>
          <div>
            <p>
              Data encryption is a critical aspect of our security strategy. We
              use the following methods to ensure the confidentiality and
              integrity of your data:
            </p>
            <ul>
              <li>
                <strong>Encryption in Transit:</strong> All data transmitted
                between users and our servers is encrypted using SSL/TLS
                protocols, ensuring protection from man-in-the-middle attacks.
              </li>
              <li>
                <strong>Encryption at Rest:</strong> Sensitive data stored on
                our servers is encrypted using AES-256 encryption to protect
                against unauthorized access in the event of a breach.
              </li>
              <li>
                <strong>Key Management:</strong> We use a secure key management
                system to handle encryption keys and ensure they are rotated
                periodically to maintain security.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Authentication and Access Control</h2>
          <div>
            <p>
              We implement strong authentication and access control measures to
              ensure that only authorized users can access sensitive data:
            </p>
            <ul>
              <li>
                <strong>Two-Factor Authentication (2FA):</strong> We offer 2FA
                for all user accounts to provide an additional layer of security
                when logging in.
              </li>
              <li>
                <strong>Role-Based Access Control (RBAC):</strong> We assign
                specific roles and permissions to users based on their
                responsibilities, ensuring that they only have access to the
                data necessary for their role.
              </li>
              <li>
                <strong>Session Management:</strong> We enforce session timeouts
                and re-authentication to minimize the risk of unauthorized
                access through abandoned sessions.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Payment Security</h2>
          <div>
            <p>
              We use secure payment processing methods to protect your financial
              information:
            </p>
            <ul>
              <li>
                <strong>Third-Party Payment Processors:</strong> We rely on
                trusted third-party payment processors (e.g., Stripe, PayPal) to
                handle payment transactions securely.
              </li>
              <li>
                <strong>PCI-DSS Compliance:</strong> Our payment processing
                partners comply with the Payment Card Industry Data Security
                Standard (PCI-DSS) to ensure the secure handling of payment
                information.
              </li>
              <li>
                <strong>Tokenization:</strong> Sensitive payment data, such as
                credit card numbers, are tokenized to protect against data
                breaches.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Incident Response Plan</h2>
          <div>
            <p>
              In the event of a security breach, we have a comprehensive
              incident response plan in place:
            </p>
            <ul>
              <li>
                <strong>Immediate Action:</strong> We will immediately
                investigate any potential security incidents to determine the
                scope and impact.
              </li>
              <li>
                <strong>Notification:</strong> Affected users will be notified
                promptly, and any necessary corrective actions will be taken.
              </li>
              <li>
                <strong>Root Cause Analysis:</strong> We will perform a root
                cause analysis to prevent similar incidents from occurring in
                the future.
              </li>
              <li>
                <strong>Collaboration with Authorities:</strong> If necessary,
                we will work with law enforcement and regulatory authorities to
                address the incident.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Regular Security Audits</h2>
          <div>
            <p>
              We perform regular security audits to identify vulnerabilities and
              ensure that our security measures are up to date:
            </p>
            <ul>
              <li>
                <strong>Vulnerability Assessments:</strong> We conduct regular
                vulnerability assessments to identify potential weaknesses in
                our infrastructure.
              </li>
              <li>
                <strong>Penetration Testing:</strong> We perform penetration
                testing to simulate real-world attacks and identify potential
                entry points for attackers.
              </li>
              <li>
                <strong>Compliance Audits:</strong> We undergo regular audits to
                ensure compliance with relevant security regulations and
                industry standards.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. User Responsibilities</h2>
          <div>
            <p>
              We encourage our users to take an active role in securing their
              accounts:
            </p>
            <ul>
              <li>
                <strong>Use Strong Passwords:</strong> Users should create
                strong, unique passwords for their accounts to prevent
                unauthorized access.
              </li>
              <li>
                <strong>Enable Two-Factor Authentication (2FA):</strong> We
                recommend enabling 2FA for an added layer of security.
              </li>
              <li>
                <strong>Monitor Account Activity:</strong> Users should
                regularly monitor their accounts for any suspicious activity and
                report it immediately.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">8. Security Improvements</h2>
          <div>
            <p>
              We are committed to continuously improving our security posture.
              This includes:
            </p>
            <ul>
              <li>
                <strong>Regular Updates:</strong> We regularly update our
                systems and software to patch security vulnerabilities and
                improve overall security.
              </li>
              <li>
                <strong>Employee Training:</strong> We provide ongoing security
                training to our employees to ensure they are aware of the latest
                threats and best practices for data protection.
              </li>
              <li>
                <strong>Security Research:</strong> We invest in security
                research to stay ahead of emerging threats and adopt new
                technologies to enhance our security.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">9. Contact Information</h2>
          <div>
            <p>
              If you have any questions or concerns about our security
              practices, please contact us:
            </p>
            <p>
              <strong>Trackmydoc, LLC</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityReview;
