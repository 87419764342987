import React from "react";

const BugBounty = () => {
  return (
      <div>
        <div className="mb-[60px]">
          <h2 className="text-[50px] h2-font">Bug Bounty Program</h2>
          <p className="text-[#7D8592] mb-4">
            At Trackmydoc, we take security seriously and believe that
            collaboration with the security research community is crucial to
            maintaining a secure platform. Our Bug Bounty Program is designed to
            reward researchers who discover and responsibly disclose security
            vulnerabilities in our platform.
          </p>
          <p className="text-[#7D8592]">
            We invite you to participate in our Bug Bounty Program to help us
            identify and fix security vulnerabilities. By doing so, you help
            ensure that our platform remains secure and that our users’ data is
            protected.
          </p>
        </div>
        <div className="space-y-14">
          <div>
            <h2 className="text-[36px]">1. Eligibility</h2>
            <div>
              <p>The Bug Bounty Program is open to individuals who:</p>
              <ul>
                <li>
                  Are at least 18 years old or have legal parental/guardian
                  consent.
                </li>
                <li>
                  Have the necessary skills and knowledge to conduct security
                  testing.
                </li>
                <li>
                  Comply with the terms and conditions outlined in this Bug
                  Bounty Program.
                </li>
              </ul>
              <p>
                By participating, you agree to follow the guidelines and
                disclose any findings in a responsible and ethical manner.
              </p>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">2. Scope of the Bug Bounty Program</h2>
            <div>
              <p>Our Bug Bounty Program covers the following:</p>
              <ul>
                <li>
                  <strong>Web Application Vulnerabilities:</strong> Security
                  issues related to our website or web-based services.
                </li>
                <li>
                  <strong>Mobile Application Vulnerabilities:</strong> Security
                  issues related to our mobile applications (iOS/Android).
                </li>
                <li>
                  <strong>API Vulnerabilities:</strong> Security issues related
                  to our public or private APIs.
                </li>
                <li>
                  <strong>Other Security Issues:</strong> Any other
                  vulnerabilities that could affect the security of our
                  platform.
                </li>
              </ul>
              <p>
                We do not offer rewards for vulnerabilities related to
                third-party services or integrations, social engineering
                attacks, or issues that do not impact the security of the
                platform.
              </p>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">3. What to Report</h2>
            <div>
              <p>
                We encourage you to report any vulnerabilities that may
                compromise the security or privacy of our platform or users.
                Common examples of security issues we are interested in include:
              </p>
              <ul>
                <li>Cross-Site Scripting (XSS)</li>
                <li>SQL Injection</li>
                <li>Authentication and Authorization Issues</li>
                <li>Cross-Site Request Forgery (CSRF)</li>
                <li>Remote Code Execution (RCE)</li>
                <li>Sensitive Data Exposure</li>
                <li>Server Misconfigurations</li>
                <li>Privilege Escalation</li>
                <li>API Security Issues</li>
              </ul>
              <p>
                Please ensure that your report includes detailed steps to
                reproduce the issue, as well as any relevant information to help
                us understand the severity of the vulnerability.
              </p>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">4. What Not to Report</h2>
            <div>
              <p>
                We ask that you do not report the following types of issues:
              </p>
              <ul>
                <li>
                  <strong>Social Engineering:</strong> Attempting to trick or
                  manipulate our team or users.
                </li>
                <li>
                  <strong>Denial of Service (DoS):</strong> Attacks that attempt
                  to disrupt or degrade the service.
                </li>
                <li>
                  <strong>Low-Risk Issues:</strong> Minor vulnerabilities that
                  do not pose a significant risk to the platform.
                </li>
                <li>
                  <strong>Out-of-Scope Vulnerabilities:</strong> Issues related
                  to third-party services or integrations that we do not
                  control.
                </li>
                <li>
                  <strong>Known Vulnerabilities:</strong> Issues that have
                  already been reported or are already publicly known.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">5. Responsible Disclosure</h2>
            <div>
              <p>
                When you find a security vulnerability, we ask that you follow
                responsible disclosure practices:
              </p>
              <ul>
                <li>
                  <strong>Do not exploit the vulnerability:</strong> Please
                  refrain from exploiting the vulnerability for personal gain or
                  malicious purposes.
                </li>
                <li>
                  <strong>Do not disrupt the service:</strong> Do not attempt to
                  disrupt the availability of the platform or impact other
                  users.
                </li>
                <li>
                  <strong>Do not share the vulnerability:</strong> Please do not
                  publicly disclose the vulnerability before it is fixed. We ask
                  that you allow us a reasonable amount of time to address the
                  issue before making it public.
                </li>
                <li>
                  <strong>Contact us directly:</strong> Report the vulnerability
                  directly to us through our designated reporting channels.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">6. Rewards and Recognition</h2>
            <div>
              <p>
                We offer rewards for valid and impactful vulnerability reports.
                The rewards are based on the severity of the vulnerability and
                the impact it has on the security of the platform. The following
                factors will be considered when determining the reward:
              </p>
              <ul>
                <li>
                  <strong>Severity:</strong> The potential impact of the
                  vulnerability on the platform and its users.
                </li>
                <li>
                  <strong>Exploitability:</strong> How easily the vulnerability
                  can be exploited by an attacker.
                </li>
                <li>
                  <strong>Uniqueness:</strong> Whether the vulnerability is new
                  or already known.
                </li>
              </ul>
              <p>
                Rewards can range from a thank-you note to monetary
                compensation, depending on the severity of the issue. All
                rewards are at our discretion, and we reserve the right to
                adjust the reward amount based on the specifics of the report.
              </p>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">7. How to Report a Bug</h2>
            <div>
              <p>
                If you discover a security vulnerability, please report it to us
                using the following methods:
              </p>
              <ul>
                <li>
                  <strong>Bug Bounty Platform:</strong> Submit your report via
                  our bug bounty platform at [bug bounty platform URL].
                </li>
                <li>
                  <strong>Email:</strong> You can also email us directly at
                  [security email address].
                </li>
              </ul>
              <p>Your report should include the following details:</p>
              <ul>
                <li>A description of the vulnerability.</li>
                <li>Steps to reproduce the issue.</li>
                <li>The impact and severity of the vulnerability.</li>
                <li>
                  Any other relevant information or screenshots to help us
                  understand the issue.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">8. Program Rules</h2>
            <div>
              <p>
                By participating in our Bug Bounty Program, you agree to the
                following rules:
              </p>
              <ul>
                <li>
                  You will not engage in any activity that could harm our users
                  or disrupt the service.
                </li>
                <li>
                  You will not attempt to exploit vulnerabilities for personal
                  gain or malicious purposes.
                </li>
                <li>
                  You will only report vulnerabilities to us and not disclose
                  them publicly until we have addressed the issue.
                </li>
                <li>
                  You will comply with all applicable laws and regulations while
                  participating in the program.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">
              9. Changes to the Bug Bounty Program
            </h2>
            <div>
              <p>
                We reserve the right to modify or discontinue the Bug Bounty
                Program at any time. Any changes will be posted on this page,
                and the updated program will take effect immediately upon
                posting. Please check this page regularly for updates.
              </p>
            </div>
          </div>
          <div>
            <h2 className="text-[36px]">10. Contact Information</h2>
            <div>
              <p>
                If you have any questions or need assistance with the Bug Bounty
                Program, please contact us:
              </p>
              <p>
                <strong>Trackmydoc, LLC</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default BugBounty;
