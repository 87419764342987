import React from "react";
import laptop1 from "../assets/img/firmGrowth.png";
import map from "../assets/img/map.png";
import clientmobileapp from "../assets/img/client-mobile-app.svg";
import appstore1 from "../assets/img/app-store-1.png";
import googleplay1 from "../assets/img/google-play-1.png";
import anualreportlogo from "../assets/img/anual-report.svg";
import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import midsizedlargefirmslogo from "../assets/img/mid-sized-large-firms.svg";
import enterpriselogo from "../assets/img/enterprise.svg";
import accountantslogo from "../assets/img/accountants.svg";
import bookkeeperslogo from "../assets/img/bookkeepers.svg";
import taxpreparerslogo from "../assets/img/tax-preparers.svg";
import capterralogo from "../assets/img/trusted/trusted-1-1.png";
import happiestuserlogo from "../assets/img/trusted/trusted-2-1.png";
import qualitychoicelogo from "../assets/img/trusted/trusted-3-1.png";
import trustedvendorlogo from "../assets/img/trusted/trusted-4-1.png";
import getapp1logo from "../assets/img/trusted/trusted-5-1.png";
import howtrackmydochelpedlogo from "../assets/img/leading-firm.png";
import irslogo from "../assets/img/integration/integration-1.png";
import danskelogo from "../assets/img/integration/integration-2.png";
import talouslogo from "../assets/img/integration/integration-3.png";
import ecflogo from "../assets/img/integration/integration-4.png";
import ctplogo from "../assets/img/integration/integration-5.png";
import naealogo from "../assets/img/integration/integration-6.png";
import ifeclogo from "../assets/img/integration/integration-7.png";
import traningincludedlogo from "../assets/img/traning-included.svg";
import Request from "./button/Request";
import FaqSection from "./Accordion/Accordion";

function Home() {

  const FirmGrowthData = [
    {
      question: "Client Management to Build Stronger Relationships",
      answer:
        "Efficient client management helps you foster long-term relationships by tracking interactions, understanding client needs, and personalizing your services. It ensures smooth communication and trust-building, leading to better client retention.",
    },
    {
      question: "Branded Client Portal and Mobile App",
      answer:
        "Offer your clients a seamless experience with a branded client portal and mobile app. These tools provide easy access to their account, documents, and updates, enhancing convenience and communication with your brand.",
    },
    {
      question: "Proposals and Engagement Letters",
      answer:
        "Streamline your workflow by generating professional proposals and engagement letters. Customize templates to suit each client and ensure legal clarity, setting the stage for a smooth collaboration.",
    },
    {
      question: "Customizable Digital Intake Forms (Organizers)",
      answer:
        "Create digital intake forms tailored to your specific needs. Customizable forms allow you to gather relevant information from clients quickly, reducing paperwork and enhancing efficiency in data collection.",
    },
    {
      question: "Time & Billing Integrated in One Workflow",
      answer:
        "Simplify your time tracking and billing processes by integrating them into one workflow. Automatically track time, generate invoices, and maintain accurate billing records, saving you time and ensuring consistent cash flow.",
    },
  ]
  const FAQData = [
    {
      question: "Store client and prospect information",
      answer: "Gather the best-known insights about your clients and leverage that data to serve them better. Empower yourself with the best CRM for small businesses and world-class CRM solutions and get all client information in one central hub,enhancing client servicing."
    },
    {
      question: "Simplified account management",
      answer: "Bring your company’s leads and clients on a single dashboard and configure your CRM hub with client-centric workflows, unlimited contacts, custom fields, tags, filters, bulk actions, and more. With an AI-powered CRM platform, establish a common link between your contacts and accounts to build and track relationships."
    },
    {
      question: "End-to-end management control",
      answer: "TrackmyDoc is your single point of organization and automation for accounting, payroll, and bookkeeping processes.Get client communication, client intake process, creation of tasks and jobs on a preset schedule, billing, engagement letters, custom CRM templates, and CRM input, all done swiftly and flawlessly with little intervention needed."
    },
    {
      question: "Onboard clients in a snap",
      answer: "Use the best CRM software to save time for your team and efficiently serve more clients. With the help of AI-powered and automated CRM platforms, facilitate easy and new client onboarding. From capturing a lead through your personalized login page to e-signing ready files, make it all simple and quick."
    },
    {
      question: "Automated reminders",
      answer: " Automate reminders to keep your clients on track without any manual intervention with the help of TrackmyDoc. Forget manual follow-ups, and with automation, quickly remind clients about pending organizers, invoices, contracts, chat messages, approvals, signatures, and file requests. With efficient e-signature tax software, billing and invoicing software, recurring invoice solutions, and more, save hours of manual work while nurturing client engagement."
    },
  ]
  return (
    <div>
      {/*hero section*/}
      <div className="hero-section pattern-line 2xl:pt-16">
        <div className="__text text-center res-mb-40 mt-0">
          <h1 className="mb-20 leading-normal text-[40px] max-xl:text-[27px] max-md:text-[20px]">
            The most effective platform to organize <br />
            <span className="primary-color mt-2">CPA, Insurance Agent and Immigration Consultant</span>
          </h1>
          <p className="f-18 mb-20">Empower and manage teams with an AI-powered CRM platform.</p>
          <div className="flex justify-center gap-4">
            <Request />
            <button className="btn btn-outline-primary">
              Start Free Trial
            </button>
          </div>
        </div>
      </div>

      {/*Anual Report Section*/}
      <div className="website-container">
        <div className="anual-report common-margin">
          <div className="grid gap-4 justify-items-end items-center lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
            <div className="__text res-mb-40">
              <h2 className="mb-20">
                Product <span className="primary-color">Overview</span>
              </h2>
              <p className="mb-20">
                A one-stop solution for CPA, Insurance Agent, and Immigration Consultant—the best CRM software and Cloud CRM solutions to seamlessly
                coordinate with clients and process workflows efficiently. Empower and manage teams with an AI-powered CRM platform.
              </p>
              <p>
                Automate every task with the powerful time-tracking software from TrackmyDoc to streamline operations, enhance
                productivity, and reduce costs. Ensure timely payments and recurring invoices with recurring invoice solutions for best
                outcomes.
              </p>
              <Request />
            </div>
            <div className="w-fit">
              <img src={anualreportlogo} alt="Annual Report" />
            </div>
          </div>
        </div>
      </div>

      {/*Industry leader trusted Section*/}
      <div className="leader-trusted common-margin">
        <div className="website-container">
          <div className="__text text-center mb-80">
            <h2 className="mb-20">Discover the Benefits That Set Us Apart</h2>
            <p className="mb-0">
              Explore the standout features that make Trackmydoc unique, offering innovation, efficiency, and reliability to enhance your
              experience and success.
            </p>
          </div>
          <div className="trusted-list h-fit">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 h-fit">
              <div className="box">
                <div className="picture">
                  <img src={capterralogo} draggable={false} alt="Capterra Logo" />
                </div>
              </div>
              <div className="box">
                <div className="picture">
                  <img src={happiestuserlogo} draggable={false} alt="Happiest User Logo" />
                </div>
              </div>
              <div className="box">
                <div className="picture">
                  <img src={qualitychoicelogo} draggable={false} alt="Quality Choice Logo" />
                </div>
              </div>
              <div className="box">
                <div className="picture">
                  <img src={trustedvendorlogo} draggable={false} alt="Trusted Vendor Logo" />
                </div>
              </div>
              <div className="box">
                <div className="picture">
                  <img src={getapp1logo} draggable={false} alt="GetApp Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="customization common-padding common-margin">
        <div className="website-container">
          <h2 className="text-center mb-80 pt-16">Why Choose Us? The Benefits of Our Platform</h2>
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {[
              {
                img: solepractitionerlogo,
                title: "CRM for client-facing teams",
                description:
                  "Manage your clients seamlessly, seize the right leads, and keep all records of your contacts and account information well-organized. In addition, CRM software enables you to collect payments, interact with clients, and gain insights into your team's performance, helping you grow revenue.",
              },
              {
                img: midsizedlargefirmslogo,
                title: "Store client information",
                description:
                  "Gather the best-known insights about your clients and leverage that data to serve them better. Empower yourself with the best CRM for small businesses and world-class CRM solutions and get all client information in one central hub, enhancing client servicing.",
              },
              {
                img: enterpriselogo,
                title: "Simplified account management",
                description:
                  "Bring your company’s leads and clients on a single dashboard and configure your CRM hub with client-centric workflows, unlimited contacts, custom fields, filters, and more. With us, establish a common link between your contacts and accounts to build and track relationships.",
              },
              {
                img: accountantslogo,
                title: "End-to-end management control",
                description:
                  "TrackmyDoc is your single point of organization and automation for accounting, payroll, and bookkeeping processes. Get client communication, client intake process, creation of tasks and jobs on a preset schedule, billing. all done swiftly and flawlessly with little intervention needed.",
              },
              {
                img: bookkeeperslogo,
                title: "Onboard clients in a snap",
                description:
                  "Use the best CRM software to save time for your team and efficiently serve more clients. With the help of AI-powered and automated CRM platforms, facilitate easy and new client onboarding. From capturing a lead through your personalized login page to e-signing ready files, make it all simple and quick.",
              },
              {
                img: taxpreparerslogo,
                title: "Automated reminders",
                description:
                  "Automate reminders to keep your clients on track without any manual intervention with the help of TrackmyDoc. Forget manual follow-ups, and with automation, quickly remind clients about pending organizers, invoices, contracts, chat messages, approvals, signatures, and file requests.",
              },
            ].map((item, index) => (
              <div key={index} className="box">
                <div className="flex items-center mb-3">
                  <div className="icon flex items-center justify-center me-3">
                    <img src={item.img} alt={item.title} />
                  </div>
                  <h5 className="mb-0">{item.title}</h5>
                </div>
                <p className="box-min-height-120">{item.description}</p>
              </div>
            ))}

          </div>
        </div>
      </div>

      <div className="common-margin how-trackmydoc">
        <div className="website-container">
          <div className="block lg:flex">
            <div className="__text res-mb-40">
              <h2>How Trackmydoc Revolutionized Operations for a Leading Firm?</h2>
              <p>
                <b className="secondary-color">TrackMyDoc</b> transformed operations for a leading firm by streamlining document
                management, enhancing efficiency, and ensuring secure, real-time access to critical files. With automated workflows,
                seamless collaboration, and robust tracking features, the firm reduced paperwork, minimized errors, and improved overall
                productivity.
              </p>
              <p className="mb-30">
                The platform’s intuitive interface and advanced security measures enabled employees to manage documents effortlessly,
                saving time and resources. By integrating TrackMyDoc, the firm achieved greater transparency, compliance, and operational
                excellence, revolutionizing the way they handled documentation and business processes.
              </p>
              <Request />
              <div className="mt-50">
                <div className="mx-auto max-w-[700px]">
                  <div className="counting-box text-center w-full">
                    <div className="flex items-center justify-around">
                      <div>
                        <h4 className="primary-color font-bold">500+</h4>
                        <p className="mb-0">Clients</p>
                      </div>
                      <div>
                        <h4 className="primary-color font-bold">25+</h4>
                        <p className="mb-0">Team Members</p>
                      </div>
                      <div>
                        <h4 className="primary-color font-bold">4</h4>
                        <p className="mb-0">Offices</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img src={howtrackmydochelpedlogo} alt="How Trackmydoc helped" />
            </div>
          </div>
        </div>

        {/* Integrations Section */}
        <div className="website-container">
          <div className="integrations-section">
            <h2 className="mb-60 text-center">Seamless Connectivity: Effortless Third-Party Integrations</h2>
            <div className="flex flex-col gap-4 items-center">
              <div className="grid grid-cols-1 gap-5 sm:px-3 md:px-10 md:grid-cols-2 lg:px-20 lg:grid-cols-4 max-sm:px-0">
                {[irslogo, danskelogo, talouslogo, ecflogo].map((logo, index) => (
                  <div key={index} className="shadow-box shadow-0">
                    <a className="_picture block text-center">
                      <img src={logo} alt="integration-logo" />
                    </a>
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <div className="grid grid-cols-1 w-full gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                  {[ctplogo, naealogo, ifeclogo].map((logo, index) => (
                    <div key={index} className="shadow-box shadow-0">
                      <a className="_picture block text-center">
                        <img src={logo} alt="integration-logo" />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin all-in-one-solution">
        <div className="website-container">
          <div className="block lg:flex justify-between items-center gap-5">
            <div className="max-w-[800px] w-full">
              <div className="_text mb-12">
                <span className="mb-4 primary-color font-medium block">
                  Comprehensive Solution in One Platform
                </span>
                <h2 className="mb-6">
                  Fuel your firm's growth with best-in-class practice management software.
                </h2>
                <p>
                  Trackmydoc offers an intuitive, modern solution to help you work efficiently in a digital world. Bring your firm's
                  critical operations, clients, and team together in one shared workspace.
                </p>
              </div>
              <div className="mb-10">
                <FaqSection data={FirmGrowthData} subTitle='' Title='' isFaq="false" />
              </div>
            </div>
            <div>
              <img src={laptop1} alt="Platform Overview" />
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin">
        <div className="website-container">
          <div className="flex flex-col items-center">
            <div className="_text text-center mb-14">
              <span className="mb-3 primary-color font-medium block">
                Central hub for managing your firm
              </span>
              <h2 className="mb-5">
                Effortlessly manage your team and clients in a single system.
              </h2>
              <p className="max-w-[1020px] mx-auto">
                Optimize your practice with a single platform for both back-office operations (workflow, CRM, reporting) and client-facing tools
                (data sharing, e-signatures, invoicing, messaging). Keep all client documents, emails, invoices, and tasks easily accessible in one shared space for your team.
              </p>
            </div>

            <div className="w-full">
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
                {[
                  { number: "3K+", text: "Completed files with our dynamic system" },
                  { number: "15K+", text: "Email and secure chat sent automatically" },
                  { number: "5K+", text: "Engagement letters generated automatically" },
                  { number: "40K+", text: "Hours saved per month per team member" },
                ].map((item, index) => (
                  <div key={index} className="bg-[#1e4387] text-center rounded-3xl shadow-md px-16 py-5">
                    <h4 className="text-[64px] text-white font-semibold mb-3">{item.number}</h4>
                    <span className="text-white text-center">{item.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="globe common-margin">
        <div className="website-container">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="max-w-[923px] w-full mb-10 lg:mb-0">
              <span className="mb-3 primary-color font-medium block">
                A Global Accounting Platform
              </span>
              <h2 className="mb-5">
                Global Reach: A Multilingual Platform for Seamless Communication
              </h2>
              <p className="text-sm mb-5">
                TrackMyDoc is a powerful multilingual platform used across the globe, designed to break language barriers and enhance global
                collaboration. With support for multiple languages, the platform ensures that teams from diverse regions can access, manage, and
                share documents seamlessly.
              </p>
              <p className="text-base mb-5">
                Whether in Europe, Asia, or the Americas, users can interact with the platform in their preferred language, boosting productivity
                and minimizing misunderstandings.
              </p>
            </div>

            <div>
              <img alt="map" src={map} className="max-w-full h-auto" />
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin">
        <div className="website-container">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-5">
            <div className="max-w-lg">
              <span className="mb-3 primary-color font-medium block">
                Mobility, Security, and Transparency
              </span>
              <h2 className="mb-5">Access TrackMyDoc from Anywhere</h2>
              <p className="mb-10">
                We value your convenience and time as much as you do. Use TrackMyDoc while you commute and have uninterrupted access to
                all workflows from any device, anywhere, anytime.
              </p>
              <div className="flex gap-3">
                <a href="#">
                  <img alt="Google Play" src={googleplay1} className="h-12" />
                </a>
                <a href="#">
                  <img alt="App Store" src={appstore1} className="h-12" />
                </a>
              </div>
            </div>

            <div className="bg-color rounded-2xl max-w-3xl w-full p-6 lg:p-8">
              <h3 className="mb-3 secondary-color">Client Mobile App</h3>
              <p className="mb-10">
                With the native iOS and Android apps, enable your clients to interact with your firm from any location. Using their
                phones, clients can scan, upload, e-sign and approve documents, complete organizers, securely pay invoices, and send you
                messages from the client portal or the mobile app.
              </p>
              <div className="text-center">
                <img alt="Client Mobile App" src={clientmobileapp} className="max-w-full h-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="common-margin">
        <div className="website-container">
          <div className="block items-center justify-between lg:flex">
            <div>
              <img src={traningincludedlogo} alt="training included" />
            </div>
            <div className="_text res-mb-40 ms-xl-5">
              <h2 className="mb-20">Implementation & team training included</h2>
              <p className="mb-50">
                Facilitate learning opportunities for everyone by opening doors. Enable file sharing both within your organization and
                with remote trainees.
              </p>
              <ul className="list-50 list-ui checked-icon">
                <li>The knowledge base</li>
                <li>Trackmydoc community</li>
                <li>Trackmydoc Academy</li>
                <li>Screen-sharing</li>
                <li>Webinars</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <FaqSection data={FAQData} subTitle='Your concerns, answered' Title='Frequently asked questions' />
    </div>
  );
}
export default Home;
