import React, { useRef } from "react";
import "./style.css";
import supportPerson from "../../assets/img/supportPerson.png";
import checkMark from "../../assets/img/Done_round.svg";
import crossMark from "../../assets/img/close.svg";
import tooltip from "../../assets/img/tooltip.svg";

function PricingTable() {
  const tableContainerRef = useRef(null);

  // Scroll table horizontally
  //  const handleScroll = (direction) => {
  //       if (tableContainerRef.current) {
  //            const scrollAmount = 300; // Amount to scroll (in pixels)
  //            if (direction === "next") {
  //                 tableContainerRef.current.scrollLeft += scrollAmount;
  //            } else {
  //                 tableContainerRef.current.scrollLeft -= scrollAmount;
  //            }
  //       }
  //  };
  return (
    <>
      <div className="bg-[#EFF1F4] py-[120px]">
        <div className="website-container">
          {/* <div className="text-[50px] font-bold leading-[40px] text-center mb-[80px] max-[496px]:text-[30px] max-[496px]:mb-[40px] relative z-10">
                              Customer service and support
                         </div> */}
          <div className="pricing-table-container" ref={tableContainerRef}>
            <table className="pricing-table">
              <thead>
                <tr>
                  <th className="font-bold text-[40px]">Features</th>
                  <th className="pt-5">
                    {/* Sole Practitioners <br /> <span>(1 license)</span> */}
                    <div className="flex flex-col items-center">
                      <img src={supportPerson} className="mb-4" />
                      <div className="text-[26px] font-semibold">Professional Plan</div>
                      <div className="flex gap-2 items-baseline flex-wrap">
                        <span className="text-[#0BAFD4] mt-3 text-[38px]">$400/Year</span>
                        <div className="flex gap-1 items-end">
                          <span className="text-[#0BAFD4] mt-3 text-[18px] line-through">$800/Year</span>
                          <img src={tooltip} alt="tooltip" className="w-5 cursor-pointer" title="🔥 Launch Offer (First 25 Clients): $400/Year (50% OFF)"/>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="pt-5">
                    {/* Small Firms <br /> <span>(2-9 licenses)</span> */}
                    <div className="flex flex-col items-center">
                      <img src={supportPerson} className="mb-4" />
                      <div className="text-[26px] font-semibold">Enterprise Plan</div>
                      <div className="flex gap-2 items-baseline flex-wrap">
                        <span className="text-[#0BAFD4] mt-3 text-[38px]">$500/Year</span>
                        <div className="flex gap-1 items-end">
                          <span className="text-[#0BAFD4] mt-3 text-[18px] line-through">$1000/Year</span>
                          <img src={tooltip} alt="tooltip" className="w-5 cursor-pointer" title="🔥 Launch Offer (First 25 Clients): $500/Year (50% OFF)"/>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-normal text-[22px]">
                    <b>Multiple Payment Gateways (Stripe, Clover, and Interac)</b>
                  </td>
                  <td>
                    <img src={crossMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">
                    <b>Customizable Invoices</b>
                  </td>
                  <td>
                    <img src={crossMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">
                    <b>Advanced Reporting & Analytics</b>
                  </td>
                  <td>
                    <img src={crossMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">
                    <b>Priority Support</b>
                  </td>
                  <td>
                    <img src={crossMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">Secure Authentication (2FA)</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">Multiple Services</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">Workflow Creation</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">User-Friendly Tax Filing</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">User Document Management & Storage</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">Single Payment Gateway Integration (Stripe, Clover, or Interac)</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">Employee Management System</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">Real-time Messaging</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">Email Notifications</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="font-normal text-[22px]">Basic Reporting</td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                  <td>
                    <img src={checkMark} className="w-[40px] h-[40px] mx-auto" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingTable;
