import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Privacy Policy</h2>
        <p className="text-[#7D8592] mb-4">
          Trackmydoc provides its services ("Services") in accordance with the
          following Terms of Service ("Terms"). These Terms are part of the
          overarching Agreement and define the conditions under which you may
          access and use the Services, which include all software, mobile
          applications, websites, and related offerings provided by Trackmydoc.
        </p>
        <p className="text-[#7D8592]">
          This Security Policy establishes the principles and guidelines for
          ensuring the confidentiality, integrity, and availability of
          Trackmydoc's information systems, assets, and data. By using the
          Services, you agree to adhere to this Security Policy, the Privacy
          Policy, and other applicable terms and policies outlined in the
          Agreement. If you do not agree to these terms, you are prohibited from
          accessing or using the Services.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Information We Collect</h2>
          <div>
            <p className="text-[#7D8592]">
              We may collect the following types of information:
            </p>
            <ul className="list-disc pl-6 text-[#7D8592]">
              <li>
                Personal Information: Name, email address, phone number, billing
                address, payment information, etc.
              </li>
              <li>
                Non-Personal Information: Browser type, operating system, IP
                address, device information, and usage data.
              </li>
              <li>
                Cookies and Tracking Technologies: To enhance your experience,
                analyze site usage, and improve our services.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. How We Use Your Information</h2>
          <p className="text-[#7D8592]">
            We may use the information we collect for purposes such as providing
            and maintaining our services, processing transactions, sending
            administrative communications, improving our website, and complying
            with legal obligations.
          </p>
        </div>
        <div>
          <h2 className="text-[36px]">3. How We Share Your Information</h2>
          <p className="text-[#7D8592]">
            We may share your information with service providers, for legal
            compliance, or during business transfers. We do not sell your
            personal information to third parties.
          </p>
        </div>
        <div>
          <h2 className="text-[36px]">4. Your Rights</h2>
          <p className="text-[#7D8592]">
            Depending on your location, you may have rights to access, correct,
            delete, or restrict processing of your personal information. Contact
            us at [your email address] to exercise your rights.
          </p>
        </div>
        <div>
          <h2 className="text-[36px]">5. Data Retention</h2>
          <p className="text-[#7D8592]">
            We retain your information only as long as necessary to fulfill the
            purposes outlined in this policy or comply with legal obligations.
          </p>
        </div>
        <div>
          <h2 className="text-[36px]">6. Data Security</h2>
          <p className="text-[#7D8592]">
            We implement measures to protect your information from unauthorized
            access, disclosure, or misuse. However, no method of transmission
            over the internet is 100% secure.
          </p>
        </div>
        <div>
          <h2 className="text-[36px]">7. Changes to This Privacy Policy</h2>
          <p className="text-[#7D8592]">
            We may update this Privacy Policy from time to time. Changes will be
            effective upon posting to this page, and the "Effective Date" will
            be updated accordingly.
          </p>
        </div>
        {/* <div>
              <h2 className="text-[36px]">8. Contact Us</h2>
              <p className="text-[#7D8592]">
                   If you have any questions or concerns about this Privacy Policy, please contact us at:
              </p>
              <ul className="list-disc pl-6 text-[#7D8592]">
                   <li>Email: [your email address]</li>
                   <li>Address: [your physical address]</li>
              </ul>
         </div> */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
