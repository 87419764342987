import React from "react";

const ReferralProgram = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Referral Program Terms</h2>
        <p className="text-[#7D8592] mb-4">
          These Referral Program Terms govern your participation in the Referral
          Program. By participating in the Program, you agree to abide by these
          Terms and any applicable laws. Please read them carefully before
          participating in the Program.
        </p>
        <p className="text-[#7D8592]">
          If you do not agree to these Terms, you should not participate in the
          Program. These Terms are in addition to any other agreements you may
          have with .
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Eligibility</h2>
          <div>
            <p>
              To be eligible to participate in the Referral Program, you must:
            </p>
            <ul>
              <li>Be a registered user or customer of .</li>
              <li>
                Have a valid email address or phone number associated with your
                account.
              </li>
              <li>
                Comply with all applicable laws and regulations related to
                referral marketing.
              </li>
            </ul>
            <p>
              Employees, contractors, or affiliates of are not eligible to
              participate in the Program.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. How the Referral Program Works</h2>
          <div>
            <p>
              As a participant in the Referral Program, you can refer
              individuals to by sharing your unique referral link or code. When
              a Referred Individual signs up for 's services and meets the
              eligibility criteria.
            </p>
            <p>
              The exact Referral Reward will be outlined on the Program page or
              communicated to you directly. Rewards may vary depending on the
              specific promotion or offer at the time of referral.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. Referral Rewards</h2>
          <div>
            <p>Referral Rewards may include, but are not limited to:</p>
            <ul>
              <li>Discounts on future purchases or services.</li>
              <li>Cash bonuses or gift cards.</li>
              <li>Free services or upgrades.</li>
            </ul>
            <p>
              The value of the Referral Reward will be specified at the time of
              the referral and is subject to change at any time. Rewards are
              non-transferable and cannot be redeemed for cash unless explicitly
              stated.
            </p>
            <p>
              Referral Rewards are subject to the terms and conditions of and
              may be adjusted or revoked if the referral is deemed fraudulent or
              if the referred individual does not meet the criteria for
              eligibility.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Referral Limitations</h2>
          <div>
            <p>
              reserves the right to limit the number of referrals you can make
              and the number of Referral Rewards you can earn within a specified
              period. Additionally, referrals must be made in good faith, and
              any attempt to manipulate the system will result in
              disqualification from the Program and forfeiture of any earned
              rewards.
            </p>
            <p>
              Referral Rewards may not be combined with other promotions,
              discounts, or offers unless explicitly stated by .
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Program Changes and Termination</h2>
          <div>
            <p>
              reserves the right to modify, suspend, or terminate the Referral
              Program at any time, without notice, at its sole discretion. If
              the Program is terminated, you will be entitled to receive any
              Referral Rewards you have earned prior to the termination date,
              provided that the referral meets all eligibility requirements.
            </p>
            <p>
              Any changes to the Program will be communicated to you via email
              or through the Program’s website.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Fraud and Abuse</h2>
          <div>
            <p>
              takes fraudulent activity seriously. Any attempt to manipulate the
              referral system, such as by submitting fake referrals or using
              fraudulent information, will result in the immediate termination
              of your participation in the Program and the forfeiture of any
              Referral Rewards.
            </p>
            <p>
              reserves the right to investigate any suspicious activity and take
              appropriate legal action as necessary.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">7. Taxes</h2>
          <div>
            <p>
              You are responsible for any taxes that may apply to the Referral
              Rewards you earn. [Your Company Name] will not be held liable for
              any taxes, fees, or other charges related to your participation in
              the Program. We recommend that you consult with a tax professional
              if you have any questions regarding tax obligations related to the
              Referral Program.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">8. Limitation of Liability</h2>
          <div>
            <p>
              To the fullest extent permitted by law, is not liable for any
              indirect, incidental, special, consequential, or punitive damages
              arising out of or in connection with the Referral Program,
              including but not limited to lost profits, revenue, or data, even
              if we were advised of the possibility of such damages.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">9. Privacy</h2>
          <div>
            <p>
              By participating in the Referral Program, you consent to the
              collection, use, and processing of your personal data as outlined
              in 's Privacy Policy. We may collect personal information such as
              your name, email address, and referral activity to administer the
              Program and provide you with rewards.
            </p>
            <p>
              We will not share your personal information with third parties
              without your consent, except as required by law or as necessary to
              fulfill the Program.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">10. Governing Law</h2>
          <div>
            <p>
              These Terms and the Referral Program will be governed by and
              construed in accordance without regard to its conflict of law
              principles. Any disputes arising from the Program will be resolved
              in the courts located.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">11. Contact Information</h2>
          <div>
            <p>
              If you have any questions about the Referral Program or these
              Terms
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralProgram;
