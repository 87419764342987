import React from "react";

const RefundPolicy = () => {
  return (
    <div>
      <div className="mb-[60px]">
        <h2 className="text-[50px] h2-font">Refund Policy</h2>
        <p className="text-[#7D8592] mb-4">
          At Trackmydoc, we strive to provide the best possible service to our
          customers. However, we understand that there may be times when you may
          need to request a refund. This Refund Policy outlines the terms and
          conditions under which refunds will be granted.
        </p>
      </div>
      <div className="space-y-14">
        <div>
          <h2 className="text-[36px]">1. Eligibility for Refunds</h2>
          <div>
            <p>Refunds will be considered under the following circumstances:</p>
            <ul>
              <li>
                <strong>Billing Errors:</strong> If you were incorrectly charged
                for services, we will provide a full refund for the overcharged
                amount.
              </li>
              <li>
                <strong>Service Not Delivered:</strong> If the service was not
                delivered as described or was unavailable for an extended
                period, you may be eligible for a refund.
              </li>
              <li>
                <strong>Unhappy with Service:</strong> If you are not satisfied
                with the service, you may request a refund within [X] days of
                purchase, provided you have not used the service extensively.
              </li>
              <li>
                <strong>Account Cancellation:</strong> If you cancel your
                account before the end of the billing cycle, we may provide a
                pro-rated refund for the unused portion of your subscription.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">2. Non-Refundable Items</h2>
          <div>
            <p>The following items and circumstances are non-refundable:</p>
            <ul>
              <li>
                <strong>Fees for Services Rendered:</strong> If services were
                rendered or partially used, no refund will be provided.
              </li>
              <li>
                <strong>Non-Usage of Service:</strong> If you did not use the
                service but the subscription period has passed, you are not
                eligible for a refund.
              </li>
              <li>
                <strong>Digital Products:</strong> Digital products such as
                downloadable resources or one-time use software licenses are
                non-refundable once accessed or downloaded.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">3. How to Request a Refund</h2>
          <div>
            <p>
              If you believe you are eligible for a refund, please follow the
              steps below:
            </p>
            <ul>
              <li>
                <strong>Contact Us:</strong> Reach out to our support team via
                email at [support email] or through the contact form on our
                website.
              </li>
              <li>
                <strong>Provide Details:</strong> Include your account details,
                a description of the issue, and the reason for the refund
                request.
              </li>
              <li>
                <strong>Refund Processing:</strong> Once your request is
                reviewed, we will process your refund request within [X]
                business days. Refunds will be issued to the original payment
                method.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">4. Processing Time</h2>
          <div>
            <p>
              Refunds are typically processed within [X] business days from the
              date your refund request is approved. The time it takes for the
              refund to appear in your account may vary depending on your
              payment provider.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">5. Changes to the Refund Policy</h2>
          <div>
            <p>
              We reserve the right to update or modify this Refund Policy at any
              time. Any changes will be posted on this page, and the updated
              policy will take effect immediately upon posting. Please review
              this policy regularly to stay informed of any updates.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-[36px]">6. Contact Information</h2>
          <div>
            <p>
              If you have any questions or concerns regarding this Refund Policy
              or need assistance with your refund request, please contact us:
            </p>
            <p>
              <strong>Trackmydoc, LLC</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
