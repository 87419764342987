import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import documentmanagment from "../assets/img/document-managment.svg";

import organizefolder from "../assets/img/organize-folder.svg";
import nativepdfeditor from "../assets/img/native-pdf-editor.svg";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";

import secureunlimiteddocument from "../assets/img/secure-unlimited-document.svg";
import reduceaccountsdocument from "../assets/img/reduce-accounts-document.svg";

import filtericon from "../assets/img/filter-icon.svg";
import securitytimeicon from "../assets/img/security-time-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";

import getfilesfromclients from "../assets/img/getfilesfromclients.svg";
import makeesigningeasyforclients from "../assets/img/makeesigningeasyforclients.svg";

import firms from "../assets/img/firms.svg";
import firms2 from "../assets/img/firms2.svg";
import { Link } from "react-router-dom";

function DocumentManagement() {
     return (
          <div>
               {/*hero section*/}
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={5}>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">Secure Document Management for Accounting Professionals</h2>
                                        <p className="mb-60">
                                             Customized and robustly designed TrackmyDoc enables CPA, Insurance Agent, and Immigration Consultant to securely
                                             store, manage, and share documents with clients at a centralized hub. With the best CRM
                                             software and online tax organizer, keep all your files well-organized and easily accessible on
                                             one platform. Complete with e-signatures, a client portal, and an integrated affordable CRM for
                                             startups, TrackmyDoc simplifies client communication and boosts productivity.
                                        </p>
                                        <div className="d-flex">
                                             <Link to="/contact" className="btn btn-primary">
                                                  Request Demo
                                             </Link>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={1}></Col>
                              <Col lg={6}>
                                   <div className="hero-picture">
                                        <img src={documentmanagment} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">
                                                  Keep your documents in one secure place
                                             </span>
                                             <h2 className="mb-20">Secure, unlimited document storage</h2>
                                             <p className="mb-60">
                                                  Upload files directly from your computer or tax program to your cloud-based portal and
                                                  securely store client documents without any limitations.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Secure unlimited document storage</Accordion.Header>
                                                       <Accordion.Body>
                                                            With the best CRM for small businesses, up your game like never before! With
                                                            AI-powered CRM and Cloud CRM solutions, upload files directly from your computer
                                                            or tax program to your cloud-based portal without any risks. Securely store
                                                            client documents without any limitations or hesitation.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Centralized file management</Accordion.Header>
                                                       <Accordion.Body>
                                                            With our versatile TrackmyDoc solution curated for CPA, Insurance Agent,Immigration Consultant and more, get
                                                            the best features with an AI-powered CRM and efficiently accommodate all
                                                            business and client files in one document management system. With well-organized
                                                            client information, your team can effortlessly access and collaborate on the
                                                            documents whenever, wherever.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Smart desktop assistant</Accordion.Header>
                                                       <Accordion.Body>
                                                            TrackmyDoc’s Windows desktop app enhances your document flow. Leave your worries
                                                            aside and effortlessly upload documents and folders from your machine directly
                                                            to your client accounts, keeping track of every activity. Establish a connection
                                                            between documents and specific jobs, upload folders in bulk, and connect
                                                            documents to invoices with our automated CRM system.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>View and edit files from Windows Explorer</Accordion.Header>
                                                       <Accordion.Body>
                                                            With TrackmyDoc’s built-in intelligence and technological innovation, manage all
                                                            documents from the virtual drive that becomes a dedicated drive on your
                                                            computer. This helps free up space on your machine, and you can accomplish any
                                                            task quickly. With the best CRM software, your team can copy and move files and
                                                            folders, upload documents manually or in bulk, edit docs locally, and auto-save
                                                            changes directly to the app.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="4">
                                                       <Accordion.Header>Integrated with all tax software</Accordion.Header>
                                                       <Accordion.Body>
                                                            TrackmyDoc helps print documents from any tax program eliminating redundant data
                                                            entry. Leverage the best e-signature tax software, client management tools, and
                                                            tax preparation software for the best business outcomes.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="5">
                                                       <Accordion.Header>Unlimited cloud-based storage</Accordion.Header>
                                                       <Accordion.Body>
                                                            TrackmyDoc facilitates unlimited, safe, secure cloud storage at no extra cost.
                                                            Get the best out of TrackmyDoc and securely store documents.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center py-5">
                                             <img src={secureunlimiteddocument} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding-top">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="5" xl={4}>
                                   <div className="_text mb-60 me-xl-2">
                                        <span className="mb-12 fw-500 d-block primary-color">Keep your documents in one secure place</span>
                                        <h2 className="mb-20">Firms effectively manage their practice with Trackmydoc</h2>
                                        <p className="mb-0">
                                             Discover how our clients reduced their paperwork and achieved seamless document flow by
                                             incorporating Trackmydoc into their practices.
                                        </p>
                                   </div>
                              </Col>
                              <Col lg="7" xl={8}>
                                   <Row>
                                        <Col md={6}>
                                             <div className="firm-box">
                                                  <img src={firms} />
                                                  <h5 className="mt-5 secondary-color mb-12">
                                                       Athene Group experience: Managing a team of 100+ staff across 10 offices with
                                                       Trackmydoc
                                                  </h5>
                                                  <p className="mb-12">
                                                       Before Trackmydoc we used one system for handling sensitive documents, one system for
                                                       sharing documents, one system for task management… Packing that into a single system
                                                       instead of 10 different ones is great.
                                                  </p>
                                                  <a className="secondary-color fw-600">Read case study</a>
                                             </div>
                                        </Col>
                                        <Col md={6}>
                                             <div className="firm-box mtop-100">
                                                  <img src={firms2} />
                                                  <h5 className="mt-5 secondary-color mb-12">
                                                       Polaris Tax & Accounting: post-acquisition expansion from 50 customers to 1,500+
                                                  </h5>
                                                  <p className="mb-12">
                                                       We don't have to worry about having external drives for the data. We don't have to
                                                       worry about having a digital signature software provider. It just makes it easier to
                                                       have everything in one single platform.
                                                  </p>
                                                  <a className="secondary-color fw-600">Read case study</a>
                                             </div>
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Get files from clients in a blink</span>
                                             <h2 className="mb-20">Integrated with the client portal</h2>
                                             <p className="mb-60">
                                                  Trackmydoc offers a document management hub that is integrated with the client portal and
                                                  CRM. It provides a multitude of simple and secure ways to receive documents from your
                                                  clients and third parties
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Clients upload docs without login</Accordion.Header>
                                                       <Accordion.Body>
                                                            Need to forward an important document to a lawyer or a financial advisor?
                                                            Securely share documents with third parties and provide expiring links for added
                                                            security measures.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Third-party sharing</Accordion.Header>
                                                       <Accordion.Body>
                                                            Need to forward an important document to a lawyer or a financial advisor?
                                                            Securely share documents with third parties and provide expiring links for added
                                                            security measures.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Streamline client feedback</Accordion.Header>
                                                       <Accordion.Body>
                                                            Need to forward an important document to a lawyer or a financial advisor?
                                                            Securely share documents with third parties and provide expiring links for added
                                                            security measures.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>

                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={getfilesfromclients} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24 res-mb-40">
                                        <div className="combine-picture text-center p-5 mb-3">
                                             <img src={reduceaccountsdocument} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6" xl={5}>
                                   <div className="col-xl-pdleft30">
                                        <div className="_text mb-60">
                                             <h2 className="mb-20">Reduce accounts receivable by locking documents to invoices</h2>
                                             <p className="mb-60">
                                                  Ensure you get paid for the work you do. You have the option of locking documents to
                                                  invoices. Documents unlock automatically as soon as the invoice is paid. This feature:
                                             </p>
                                             <ul className="list-ui checked-icon">
                                                  <li>Automates payment collection</li>
                                                  <li>Ensures that your work is paid</li>
                                                  <li>Improves your billing processes</li>
                                             </ul>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">
                                                  Arrange your files in a way that suits you best
                                             </span>
                                             <h2 className="mb-20">Organize files and folders</h2>
                                             <p className="f-18 mb-60">
                                                  Create folder templates, control access rights, assign document statuses and apply filters
                                                  to find files faster.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>File permissions for increased security</Accordion.Header>
                                                       <Accordion.Body>
                                                            Apply templates with one click or by using automations. Have your folders
                                                            templates ready and apply them to new and existing accounts to streamline the
                                                            process of organizing client documents.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Folder templates</Accordion.Header>
                                                       <Accordion.Body>
                                                            Apply templates with one click or by using automations. Have your folders
                                                            templates ready and apply them to new and existing accounts to streamline the
                                                            process of organizing client documents.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Create predefined folder structure</Accordion.Header>
                                                       <Accordion.Body>
                                                            Apply templates with one click or by using automations. Have your folders
                                                            templates ready and apply them to new and existing accounts to streamline the
                                                            process of organizing client documents.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Document status</Accordion.Header>
                                                       <Accordion.Body>
                                                            Apply templates with one click or by using automations. Have your folders
                                                            templates ready and apply them to new and existing accounts to streamline the
                                                            process of organizing client documents.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="4">
                                                       <Accordion.Header>Search and filter</Accordion.Header>
                                                       <Accordion.Body>
                                                            Apply templates with one click or by using automations. Have your folders
                                                            templates ready and apply them to new and existing accounts to streamline the
                                                            process of organizing client documents.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={organizefolder} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Make e-signing easy for clients</span>
                                             <h2 className="mb-20">Unlimited integrated e-signatures</h2>
                                             <p className="mb-60">
                                                  Experience the convenience of unlimited e-signatures integrated with the client portal.
                                                  Your clients can upload documents, review and approve them, and then e-sign them all from
                                                  one, user-friendly, custom-branded client portal.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Request e-signatures from clients</Accordion.Header>
                                                       <Accordion.Body>
                                                            Add the e-signature request to the documents you upload via the portal or the
                                                            desktop app. Specify where you want them to e-sign, include fields for both the
                                                            taxpayer and their spouse. Set up reminders to make sure clients don’t forget to
                                                            sign.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>KBA available (IRS requirement)</Accordion.Header>
                                                       <Accordion.Body>
                                                            Add the e-signature request to the documents you upload via the portal or the
                                                            desktop app. Specify where you want them to e-sign, include fields for both the
                                                            taxpayer and their spouse. Set up reminders to make sure clients don’t forget to
                                                            sign.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Sign from any device</Accordion.Header>
                                                       <Accordion.Body>
                                                            Add the e-signature request to the documents you upload via the portal or the
                                                            desktop app. Specify where you want them to e-sign, include fields for both the
                                                            taxpayer and their spouse. Set up reminders to make sure clients don’t forget to
                                                            sign.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>
                                                            Create dependency — trigger emails and tasks after e-signature completed
                                                       </Accordion.Header>
                                                       <Accordion.Body>
                                                            Add the e-signature request to the documents you upload via the portal or the
                                                            desktop app. Specify where you want them to e-sign, include fields for both the
                                                            taxpayer and their spouse. Set up reminders to make sure clients don’t forget to
                                                            sign.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center mt-5 p-5">
                                             <img src={makeesigningeasyforclients} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="combine-picture text-center p-5 bg-color radius-24 res-mb-40">
                                        <img src={nativepdfeditor} />
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="col-xl-pdleft78">
                                        <div className="_text">
                                             <h2 className="mb-20">Native PDF editor</h2>
                                             <p className="mb-60">
                                                  Bypass Adobe, NitroPDF and other paid tools by using Trackmydoc native PDF editor. No need
                                                  to download and upload — everything is auto-saved within Trackmydoc.
                                             </p>
                                             <ul className="list-ui checked-icon">
                                                  <li>Merge and annotate PDFs</li>
                                                  <li>Leave notes and revise</li>
                                                  <li>Send fillable PDF forms to your clients</li>
                                             </ul>
                                             <div className="d-flex mt-60">
                                                  <a className="btn btn-primary">Request Demo</a>
                                             </div>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding">
                    <div className="website-container">
                         <div className="text-center mb-80">
                              <h2 className="text-center mb-20">Centralized hub for firm & client documents</h2>
                              <p className="mx-1020 mb-0">
                                   Connect the entire lifecycle of your internal documents and client-facing documents with client and team
                                   activities and seamlessly manage your practice with minimum clicks and maximum productivity.
                              </p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">PDF editor</h5>
                                        </div>
                                        <p className="min-72">
                                             Merge PDFs, highlight, make notes or send fillable PDF forms to your clients
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={securitytimeicon} />
                                             </div>
                                             <h5 className="mb-0">Cloud storage</h5>
                                        </div>
                                        <p className="min-72">Store your practice documents securely, with no limits or additional costs</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={printericon} />
                                             </div>
                                             <h5 className="mb-0">Desktop app</h5>
                                        </div>
                                        <p className="min-72">Upload documents from your computer directly to your client accounts</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={folderopenicon} />
                                             </div>
                                             <h5 className="mb-0">Virtual drive</h5>
                                        </div>
                                        <p className="min-72">Manage all documents, freeing up space on your computer</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Folder templates</h5>
                                        </div>
                                        <p className="min-72">Apply templates with one click or through automations</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">Share with third parties</h5>
                                        </div>
                                        <p className="min-72">Securely share documents with third parties and provide expiring links</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Unlimited e-signatures</h5>
                                        </div>
                                        <p className="min-72">E-signatures integrated with the client portal</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">Bank-level security</h5>
                                        </div>
                                        <p className="min-72">A secure home for your firm's valuable documents</p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default DocumentManagement;
