// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import CustomizableTaxOrganizers from "../assets/img/CustomizableTaxOrganizers.svg";

import laptop1 from "../assets/img/laptop-1.svg";
import device1 from "../assets/img/device-1.svg";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import templates from "../assets/img/templates.svg";

import filtericon from "../assets/img/filter-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";
import { Link } from "react-router-dom";

function TaxOrganizers() {
     return (
          <div>
               {/*hero section*/}
               <div className="hero-section">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={5}>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">Customizable Tax Organizers</h2>
                                        <p className="mb-20">
                                             Leverage the benefits of fully customizable digital questionnaires exclusively designed and
                                             curated for CPA, Insurance Agent, and Immigration Consultant to help them streamline client onboarding for CPA,
                                             Insurance Agent, and Immigration Consultant. Say goodbye to outdated PDFs and tedious back-and-forth
                                             communication. Enhance client experience and save time with our new-age CRM software platform
                                             and Cloud CRM solutions while saving your time. Our robust time-tracking software enables a
                                             smooth onboarding process, providing our clients with personalized and secure forms tailored to
                                             their needs.
                                        </p>
                                        <div className="d-flex">
                                             <Link to="/contact" className="btn btn-primary">
                                                  Request Demo
                                             </Link>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={1}></Col>
                              <Col lg={6}>
                                   <div className="hero-picture">
                                        <img src={CustomizableTaxOrganizers} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="_text mb-60">
                                        <span className="mb-12 fw-500 d-block primary-color">Get all your questions answered</span>
                                        <h2 className="mb-20">Customizable & automated: gather essential client information easily</h2>
                                        <p className="mb-20">
                                             Explore TrackmyDoc’s enormous benefits and prepare and send tailor-made client tax organizers
                                             whenever you need to collect information from your clients. Securely gather all the necessary
                                             details for new client intake, personal returns, business returns, and bookkeeping without
                                             sending emails and documents repeatedly.
                                        </p>
                                        <p className="mb-20">
                                             With automated CRM solutions, you can send organizers to your clients any time. You can
                                             automate and set triggers based on dates. Our powerful platform helps you organize and
                                             integrate everything with the rest of your practice management platform.
                                        </p>
                                   </div>
                                   <div className="accordion mb-01 me-3 res-mb-40">
                                        <Accordion defaultActiveKey="1">
                                             <Accordion.Item eventKey="0">
                                                  <Accordion.Header>Integration with CRM</Accordion.Header>
                                                  <Accordion.Body>
                                                       Automated CRM solutions and an AI-powered CRM platform can help update account
                                                       information in the organizer. With population data, you can track the entire chain of
                                                       events, offer follow-up services based on the information they provide, and segment
                                                       your clients seamlessly.
                                                  </Accordion.Body>
                                             </Accordion.Item>
                                             <Accordion.Item eventKey="1">
                                                  <Accordion.Header>
                                                       Assign tasks automatically based on the organizer's answers
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                       With the power of TrackmyDoc, you can facilitate automated removal or assigning of
                                                       account tags based on client responses in organizers. This can help you optimize
                                                       onboarding and similar tasks, such as utilizing tag-based conditional automation to
                                                       the next stage of your pipeline, assigning the right tasks to the right team members,
                                                       or sending personalized messages to your clients.
                                                  </Accordion.Body>
                                             </Accordion.Item>
                                             <Accordion.Item eventKey="2">
                                                  <Accordion.Header>Automate onboarding</Accordion.Header>
                                                  <Accordion.Body>
                                                       Automate the steps your client requires and create seamless processes for the client
                                                       to work efficiently with you. With TrackmyDoc, provide clients with organizers and
                                                       help them fill out basic information, have them sign an engagement letter and more.
                                                       Leverage the dynamic features of our platform with e-signature tax software and an
                                                       automated CRM system.
                                                       <p className="mt-3">
                                                            TrackmyDoc takes over all admin tasks and lets you save time and money. Automate
                                                            tasks, and use your time productively. Use our AI-powered CRM platform to
                                                            customize the automation process and achieve your business goals. Additional
                                                            automation can be triggered after the engagement letter is signed. You can use
                                                            the best CRM software, Cloud CRM solutions, small business tax tools, client
                                                            management tools, and more with TrackmyDoc.
                                                       </p>
                                                  </Accordion.Body>
                                             </Accordion.Item>
                                             <Accordion.Item eventKey="3">
                                                  <Accordion.Header>Save time by applying organizer templates</Accordion.Header>
                                                  <Accordion.Body>
                                                       Unique question sets are highly demanded by corporate, bookkeeping, and personal tax
                                                       clients. Our automated TrackmyDoc empowers you and lets you create different
                                                       organizers to cover every situation your firm may require. Use an online tax
                                                       organizer to provide the optimal client experience you desire. With automated CRM,
                                                       create your organizers or reuse TrackmyDoc's collection of templates to customize
                                                       them per your needs.
                                                  </Accordion.Body>
                                             </Accordion.Item>
                                        </Accordion>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-850 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={laptop1} />
                                             <img className="second-img" src={device1} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding">
                    <div className="website-container">
                         <div className="text-center mb-80">
                              <h2 className="text-center mb-20">Create tax organizers customized to your clients</h2>
                              <p className="mx-1020 mb-0">
                                   Make use of various organizer features to gather and sort client data. Send 1 or 10,000 at just the right
                                   time, either manually or automatically, and receive timely notifications when they're completed.
                              </p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Secure access</h5>
                                        </div>
                                        <p className="min-72">Ensure the protection of client data with secure organizer access</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={templates} />
                                             </div>
                                             <h5 className="mb-0">Templates</h5>
                                        </div>
                                        <p className="min-72">Save time by creating organizer templates</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={printericon} />
                                             </div>
                                             <h5 className="mb-0">Conditions</h5>
                                        </div>
                                        <p className="min-72">Set conditions to skip questions or show previous answers</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={folderopenicon} />
                                             </div>
                                             <h5 className="mb-0">Custom fields</h5>
                                        </div>
                                        <p className="min-72">
                                             Choose the optimal answer format for your clients: radio buttons, checkboxes, etc.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Tags</h5>
                                        </div>
                                        <p className="min-72">Automatically integrate answers from organizers into your CRM using tags</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">Audit trail</h5>
                                        </div>
                                        <p className="min-72">
                                             A detailed record of the organizer questions and answers is saved in the audit trail
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Automations</h5>
                                        </div>
                                        <p className="min-72">
                                             Automate when your organizer is sent to clients and set reminders to nudge them
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">Linking to jobs</h5>
                                        </div>
                                        <p className="min-72">
                                             Run your practice smoothly by linking organizers to jobs to incorporate them into your workflow
                                        </p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default TaxOrganizers;
